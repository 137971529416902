.terms div {
    padding: 1rem 0;
}

.terms div h4 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: rgb(79, 84, 91);
}

.terms div p {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: rgb(79, 84, 91);
}