@tailwind base;

@layer base {
    html {
        scroll-behavior: smooth;
    }

    body {
        font-family: 'Inter', sans-serif;
        background-color: #f6f9fc;
    }
}

@tailwind components;

@layer components {
    .nav-active {
        @apply underline underline-offset-4 decoration-2 text-green-800 transition-all ease-in duration-100;
    }
}

@tailwind utilities;