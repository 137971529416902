h1 {
    font-size: 2em;
    margin: .67em 0
}


b,
strong {
    font-weight: inherit
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

dfn {
    font-style: italic
}

mark {
    background-color: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

audio,
video {
    display: inline-block
}

audio:not([controls]) {
    display: none;
    height: 0
}

img {
    border-style: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

details,
menu {
    display: block
}

summary {
    display: list-item
}

canvas {
    display: inline-block
}

template {
    display: none
}

[hidden] {
    display: none
}

@media(min-width:770px) {
    .u-mobile-only {
        display: none
    }
}

@media(max-width:770px) {
    .u-desktop-only {
        display: none
    }
}

.u-centered {
    text-align: center
}

*,
*::after,
*::before {
    box-sizing: border-box
}

body.has-overlay {
    overflow: hidden
}


blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
    margin: 0;
    font-size: inherit;
    font-weight: inherit
}

button {
    font-family: nunito sans, Helvetica, sans-serif;
    background: 0 0;
    border: 0;
    padding: 0;
    cursor: pointer;
    outline: none
}



fieldset {
    margin: 0;
    border: 0;
    padding: 0
}

iframe {
    border: 0
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style: none
}

svg:not(:root) {
    overflow: hidden;
}

a {
    color: currentColor
}


::-moz-placeholder {
    color: #666
}



::placeholder {
    color: #666
}

::-moz-selection {
    background-color: #d6fffd;
    text-shadow: 0 0 #d6fffd
}

::selection {
    background-color: #d6fffd;
    text-shadow: 0 0 #d6fffd
}

label[for] {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

mark {
    background-color: #fff9ae
}



.hide {
    display: none
}

.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle
}

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.select2-container .select2-selection--single .select2-selection__clear {
    position: relative
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px
}

.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap
}

.select2-container .select2-search--inline {
    float: left
}

.select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    padding: 0
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.select2-dropdown {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051
}

.select2-results {
    display: block
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0
}

.select2-results__option {
    padding: 6px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.select2-results__option[aria-selected] {
    cursor: pointer
}

.select2-container--open .select2-dropdown {
    left: 0
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-search--dropdown {
    display: block;
    padding: 4px
}

.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.select2-search--dropdown.select2-search--hide {
    display: none
}

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity=0)
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 700
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
    float: left
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px
}

.select2-container--default .select2-selection--multiple {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    list-style: none
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 700;
    margin-top: 5px;
    margin-right: 10px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    margin-right: 2px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #333
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
    float: right
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid #000 1px;
    outline: 0
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa
}

.select2-container--default .select2-search--inline .select2-search__field {
    background: 0 0;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield
}

.select2-container--default .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto
}

.select2-container--default .select2-results__option[role=group] {
    padding: 0
}

.select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #ddd
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: #fff
}

.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px
}

.select2-container--classic .select2-selection--single {
    background-color: #f7f7f7;
    border: 1px solid #aaa;
    border-radius: 4px;
    outline: 0;
    background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0)
}

.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 700;
    margin-right: 10px
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0)
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
    float: left
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #aaa;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 1px;
    right: auto
}

.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid #5897fb
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: 0 0;
    border: none
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0)
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0)
}

.select2-container--classic .select2-selection--multiple {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0
}

.select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    margin-right: 2px
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
    float: right
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid #5897fb
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0
}

.select2-container--classic .select2-search--inline .select2-search__field {
    outline: 0;
    box-shadow: none
}

.select2-container--classic .select2-dropdown {
    background-color: #fff;
    border: 1px solid transparent
}

.select2-container--classic .select2-dropdown--above {
    border-bottom: none
}

.select2-container--classic .select2-dropdown--below {
    border-top: none
}

.select2-container--classic .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto
}

.select2-container--classic .select2-results__option[role=group] {
    padding: 0
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
    color: grey
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: #3875d7;
    color: #fff
}

.select2-container--classic .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px
}

.select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #5897fb
}

.select2-container--default {
    width: 100%;
    text-align: left
}

.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--single {
    border: 1px solid #ccc;
    border-radius: 2px
}

.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--single .select2-selection__choice {
    background-color: #35b4ac;
    border: none;
    border-radius: 2px;
    padding: 7px 5px 5px 6px;
    font-family: nunito sans, Helvetica, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    color: #fff !important
}

.select2-container .select2-selection--multiple,
.select2-container .select2-selection--single {
    min-height: 39px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #35b4ac
}

.select2-container .select2-search--inline .select2-search__field {
    padding: 5px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 36px
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 6px;
    right: 6px
}

.select2-dropdown {
    background-color: #fff;
    border: 1px solid #ccc
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    margin-right: 4px
}

.select2-selection__placeholder {
    color: #212121 !important
}

.l-wrapper {
    position: relative
}

.l-content {
    width: 100%
}

.l-slat--bubbles {
    /* background-image:url(/assets/img/bg-bubbles-tile.png); */
    background-repeat: repeat
}

.l-slat--standout {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
    padding-top: 10px;
    padding-bottom: 10px
}

.l-slat--filled {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc
}

.l-content--full-bleed .l-slat {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.l-content--full-bleed+.SiteFooter {
    margin-top: 0
}

.l-container {
    max-width: 1440px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: auto;
    margin-right: auto;
    width: 100%
}

.l-container--narrow {
    margin-left: auto;
    margin-right: auto
}

@media(min-width:771px) {
    .l-container--narrow {
        padding-left: 25px;
        padding-right: 25px
    }
}

@media(min-width:1031px) {
    .l-container--narrow {
        padding-left: 8.33333%;
        padding-right: 8.33333%
    }
}

.l-tpl--index .l-tpl-header {
    padding-top: 30px;
    padding-bottom: 30px
}

@media screen and (min-width:380px) {
    .l-tpl--index .l-tpl-header {
        padding-top: calc(30px + 50 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl--index .l-tpl-header {
        padding-top: 80px
    }
}

@media screen and (min-width:380px) {
    .l-tpl--index .l-tpl-header {
        padding-bottom: calc(30px + 30 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl--index .l-tpl-header {
        padding-bottom: 60px
    }
}

.l-tpl--index .l-tpl-header .PageHead+* {
    margin-top: 30px
}

@media screen and (min-width:380px) {
    .l-tpl--index .l-tpl-header .PageHead+* {
        margin-top: calc(30px + 30 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl--index .l-tpl-header .PageHead+* {
        margin-top: 60px
    }
}

.l-tpl--index .l-tpl-content {
    padding-top: 30px;
    padding-bottom: 30px
}

@media screen and (min-width:380px) {
    .l-tpl--index .l-tpl-content {
        padding-top: calc(30px + 30 * (100vw - 380px)/1060);
        padding-bottom: calc(30px + 30 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl--index .l-tpl-content {
        padding-top: 60px;
        padding-bottom: 60px
    }
}

.l-tpl--index .l-tpl-content .NoResults {
    padding-top: 30px;
    padding-bottom: 30px
}

@media screen and (min-width:380px) {
    .l-tpl--index .l-tpl-content .NoResults {
        padding-top: calc(30px + 30 * (100vw - 380px)/1060);
        padding-bottom: calc(30px + 30 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl--index .l-tpl-content .NoResults {
        padding-top: 60px;
        padding-bottom: 60px
    }
}

.l-tpl--standard .l-tpl-header,
.l-tpl--articles .l-tpl-header {
    padding-top: 30px;
    margin-bottom: 30px
}

@media screen and (min-width:380px) {

    .l-tpl--standard .l-tpl-header,
    .l-tpl--articles .l-tpl-header {
        padding-top: calc(30px + 50 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {

    .l-tpl--standard .l-tpl-header,
    .l-tpl--articles .l-tpl-header {
        padding-top: 80px
    }
}

@media(min-width:770px) {

    .l-tpl--spa .SectionHead--content-banner .SectionHead-inner,
    .l-tpl--article .SectionHead--content-banner .SectionHead-inner,
    .l-tpl--pullup .SectionHead--content-banner .SectionHead-inner {
        padding-top: 30px;
        padding-bottom: 60px
    }
}

@media screen and (min-width:770px) and (min-width:380px) {

    .l-tpl--spa .SectionHead--content-banner .SectionHead-inner,
    .l-tpl--article .SectionHead--content-banner .SectionHead-inner,
    .l-tpl--pullup .SectionHead--content-banner .SectionHead-inner {
        padding-top: calc(30px + 50 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:770px) and (min-width:1440px) {

    .l-tpl--spa .SectionHead--content-banner .SectionHead-inner,
    .l-tpl--article .SectionHead--content-banner .SectionHead-inner,
    .l-tpl--pullup .SectionHead--content-banner .SectionHead-inner {
        padding-top: 80px
    }
}

@media screen and (min-width:770px) and (min-width:380px) {

    .l-tpl--spa .SectionHead--content-banner .SectionHead-inner,
    .l-tpl--article .SectionHead--content-banner .SectionHead-inner,
    .l-tpl--pullup .SectionHead--content-banner .SectionHead-inner {
        padding-bottom: calc(60px + 120 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:770px) and (min-width:1440px) {

    .l-tpl--spa .SectionHead--content-banner .SectionHead-inner,
    .l-tpl--article .SectionHead--content-banner .SectionHead-inner,
    .l-tpl--pullup .SectionHead--content-banner .SectionHead-inner {
        padding-bottom: 180px
    }
}

@media(min-width:770px) {

    .l-tpl--spa .l-tpl-header,
    .l-tpl--article .l-tpl-header,
    .l-tpl--pullup .l-tpl-header {
        padding-top: 0
    }

    .l-tpl--spa .l-tpl-content,
    .l-tpl--article .l-tpl-content,
    .l-tpl--pullup .l-tpl-content {
        margin-top: -40px
    }
}

@media screen and (min-width:770px) and (min-width:380px) {

    .l-tpl--spa .l-tpl-content,
    .l-tpl--article .l-tpl-content,
    .l-tpl--pullup .l-tpl-content {
        margin-top: calc(-40px + -120 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:770px) and (min-width:1440px) {

    .l-tpl--spa .l-tpl-content,
    .l-tpl--article .l-tpl-content,
    .l-tpl--pullup .l-tpl-content {
        margin-top: -160px
    }
}

.l-tpl--articles .ListingSort,
.l-tpl--blog .ListingSort {
    margin: 10px 0 30px
}

.l-tpl--articles .ArticlePagination,
.l-tpl--blog .ArticlePagination {
    padding-top: 20px;
    padding-bottom: 30px
}

@media screen and (min-width:380px) {

    .l-tpl--articles .ArticlePagination,
    .l-tpl--blog .ArticlePagination {
        padding-top: calc(20px + 20 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {

    .l-tpl--articles .ArticlePagination,
    .l-tpl--blog .ArticlePagination {
        padding-top: 40px
    }
}

@media screen and (min-width:380px) {

    .l-tpl--articles .ArticlePagination,
    .l-tpl--blog .ArticlePagination {
        padding-bottom: calc(30px + 30 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {

    .l-tpl--articles .ArticlePagination,
    .l-tpl--blog .ArticlePagination {
        padding-bottom: 60px
    }
}

@media(max-width:1030px) {
    .l-tpl--competitions .FeaturedCards {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px
    }
}

@media(min-width:1031px) {
    .l-tpl--competitions .SectionHead-intro {
        padding-bottom: 6%
    }

    .l-tpl--competitions .FeaturedCards {
        margin-top: -15%
    }

    .l-tpl--competitions .FeaturedCards-title {
        color: #fff
    }

    .l-tpl--competitions .Panel,
    .l-tpl--competitions .Panel-section {
        overflow: visible
    }
}

.l-tpl--homepage .Adslot {
    padding-top: 25px;
    padding-bottom: 25px
}

@media screen and (min-width:380px) {
    .l-tpl--homepage .Adslot {
        padding-top: calc(25px + 25 * (100vw - 380px)/1060);
        padding-bottom: calc(25px + 25 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl--homepage .Adslot {
        padding-top: 50px;
        padding-bottom: 50px
    }
}

.l-tpl--homepage .SectionHead--content-banner .SectionHead-inner {
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative
}

@media screen and (min-width:380px) {
    .l-tpl--homepage .SectionHead--content-banner .SectionHead-inner {
        padding-top: calc(25px + 25 * (100vw - 380px)/1060);
        padding-bottom: calc(25px + 25 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl--homepage .SectionHead--content-banner .SectionHead-inner {
        padding-top: 50px;
        padding-bottom: 50px
    }
}

@media(max-width:1029px) {
    .l-tpl--homepage .SectionHead--content-banner .SectionHead-inner {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .l-tpl--homepage .SectionHead--content-banner .SectionHead-inner .SpaBadge {
        margin: 20px auto 0
    }
}

@media(min-width:1031px) {
    .l-tpl--homepage .SectionHead--content-banner .SectionHead-inner .SpaBadge {
        position: absolute;
        bottom: 30px;
        right: 30px
    }
}

.l-tpl--homepage .l-tpl-section {
    margin-top: 15px;
    margin-bottom: 25px
}

@media screen and (min-width:380px) {
    .l-tpl--homepage .l-tpl-section {
        margin-top: calc(15px + 15 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl--homepage .l-tpl-section {
        margin-top: 30px
    }
}

@media screen and (min-width:380px) {
    .l-tpl--homepage .l-tpl-section {
        margin-bottom: calc(25px + 25 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl--homepage .l-tpl-section {
        margin-bottom: 50px
    }
}

@media(min-width:771px) {
    .l-tpl--homepage .Panel--plain .CardSet {
        margin-left: -15px;
        margin-right: -15px
    }
}

.l-tpl--homepage .l-slat--signup-strip {
    position: relative;
    margin-top: 50px;
    margin-bottom: 30px
}

@media screen and (min-width:380px) {
    .l-tpl--homepage .l-slat--signup-strip {
        margin-top: calc(50px + 30 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl--homepage .l-slat--signup-strip {
        margin-top: 80px
    }
}

@media screen and (min-width:380px) {
    .l-tpl--homepage .l-slat--signup-strip {
        margin-bottom: calc(30px + 30 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl--homepage .l-slat--signup-strip {
        margin-bottom: 60px
    }
}

.l-tpl--homepage .l-slat--signup-strip:before {
    content: '';
    /* background-image:url(/assets/img/bg-bubbles-tile.png); */
    background-repeat: repeat;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    height: 80%;
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.l-tpl--homepage .l-slat--signup-strip .l-container {
    z-index: 10;
    position: relative
}

.l-tpl--homepage .l-container--holidays {
    /* background-image:url(/assets/img/plane.svg); */
    background-repeat: no-repeat;
    background-position: 0 30px;
    background-size: 70%
}

.l-tpl--homepage .l-container--holidays .Panel {
    background-color: transparent
}

.l-tpl--location .l-tpl-footer {
    margin-top: 30px
}

@media screen and (min-width:380px) {
    .l-tpl--location .l-tpl-footer {
        margin-top: calc(30px + 30 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl--location .l-tpl-footer {
        margin-top: 60px
    }
}

.l-tpl--ratings .l-tpl-content {
    margin-top: 0
}

@media(max-width:770px) {
    .l-tpl--ratings .BubbleRatingCardSet {
        padding-top: 20px
    }
}

@media(min-width:771px) {
    .l-tpl--ratings .SectionHead-intro {
        padding-bottom: 6%
    }

    .l-tpl--ratings .BubbleRatingCardSet {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-top: -11vw
    }

    .l-tpl--ratings .BubbleRatingCardSet-title {
        color: #fff
    }

    .l-tpl--ratings .Panel,
    .l-tpl--ratings .Panel-section {
        overflow: visible
    }
}

@media(min-width:771px) {

    .l-tpl--competitions .FeaturedCards .CardSet-card,
    .l-tpl--index .FeaturedCards .CardSet-card {
        max-width: 50%;
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%
    }
}

@media(max-width:769px) {

    .l-tpl--competitions .Panel-section--full,
    .l-tpl--index .Panel-section--full {
        padding: 0
    }
}

.l-grid {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: -25px;
    margin-bottom: -25px
}

.l-grid--centered {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.l-unit {
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding-left: 25px;
    padding-bottom: 25px
}

.Avatar {
    border: 5px solid #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
    border-radius: 100%;
    width: 90px;
    height: 90px;
    overflow: hidden
}

.Avatar-image {
    border-radius: 100%;
    width: 90px;
    height: 90px
}

.BorderBox {
    border: 1px solid #c7c7c7;
    padding: 20px 15px;
    background-color: #fbfbfb
}

.BorderBox .Form {
    margin-left: 0
}

.BorderBox .InfoBlock {
    margin-top: 10px
}

@media(min-width:770px) {
    .BorderBox {
        padding: 25px 30px
    }
}

.BorderBox+.BorderBox {
    border-top: 0
}

.BorderBox--light {
    background-color: #fff
}

.Button {
    background-color: #eb0a5e;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    text-align: center;
    border: 2px solid transparent;
    transition: all .2s ease-in-out
}


.Button:hover {
    background-color: #a40f51
}

.Button-text {
    text-transform: uppercase;
    padding: .7em 2em;
    display: inline-block;
    font-size: 14px;
    font-family: nunito sans, Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 1px
}

.Button-load {
    width: 18px;
    height: 18px;
    position: relative;
    top: 5px;
    right: 16px;
    display: none
}

.Button.is-loading .Button-load {
    display: inline-block
}

.Button--secondary {
    background: #fff;
    border-color: currentColor;
    color: #212121
}

.Button--secondary:hover {
    background: #fff;
    color: #35b4ac
}

.Button--tertiary {
    background: #fff;
    border-color: #bbb;
    color: #999
}

.Button--tertiary:hover {
    background: #fff;
    color: #212121;
    border-color: #212121
}

.Button--micro {
    background: #fff;
    border-color: #ccc;
    color: #0c717a;
    border-width: 1px
}

.Button--micro .Button-text {
    font-size: 12px;
    padding: .4em 2em;
    line-height: 1
}

.Button--micro:hover {
    background: #fff;
    border-color: #35b4ac
}

.Button--full {
    width: 100%
}

.Button--error {
    color: red !important;
    background: #fff !important
}

.Field {
    max-width: 500px
}

.Field--textarea {
    max-width: none
}

.Field-label {
    margin-bottom: 5px;
    margin-left: 2px;
    display: block
}

.Field-errorText {
    font-size: 15px;
    color: #cb2027
}



.Field-multi {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}


.Field--unlabelled .Field-label {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important
}

.Field--inline .Field-label {
    display: inline-block;
    margin-right: 15px
}


.Field--checkbox,
.Field--radio {
    padding-left: 6px;
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center
}

.Field--checkbox label,
.Field--radio label {
    padding-left: 30px;
    position: relative;
    margin-bottom: 0;
    width: 100%;
    font-weight: 400
}

.Field--checkbox label:after,
.Field--checkbox label:before,
.Field--radio label:after,
.Field--radio label:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0
}

.Field--checkbox label:before,
.Field--radio label:before {
    height: 21px;
    width: 21px;
    background-color: #fff;
    border: 2px solid #e4e3ea
}

.Field--checkbox label:after,
.Field--radio label:after {
    display: none
}

.Field--checkbox.is-error label:before,
.Field--radio.is-error label:before {
    border-color: #cb2027
}



.Field--checkbox+.Field--checkbox,
.Field--radio+.Field--checkbox,
.Field--checkbox+.Field--radio,
.Field--radio+.Field--radio {
    margin-top: 10px
}

.Field--radio label:after,
.Field--radio label:before {
    border-radius: 100%
}

.Field--radio label:after {
    height: 11px;
    width: 11px;
    left: 5px;
    top: 5px;
    background-color: #0c717a
}

.Field--checkbox label:after,
.Field--checkbox label:before {
    border-radius: 2px
}

.Field--checkbox label:after {
    height: 21px;
    width: 21px;
    /* background:url(/assets/img/tick.svg) no-repeat; */
    background-position: 5px 2px;
    background-size: 12px 14px;
    border-radius: 100%
}

.Field--required label:after,
.Field--inline--radio span.required:after {
    content: '*';
    color: #cb2027;
    font-family: arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-left: 4px
}


.Field.is-error .cke_chrome {
    border-color: #cb2027;
    margin-bottom: 6px
}

.Field.is-error .Field-errorText {
    margin-top: 6px
}


.toggleFields {
    display: none;
    margin-top: 0 !important
}

.toggleFields .Field--text {
    margin-bottom: 20px
}

.Field--inline--radio {
    padding: 5px 0;
    flex-wrap: wrap
}

.Field--inline--radio span,
.Field--inline--radio div {
    margin-right: 10px;
    position: relative
}

.Field--inline--radio span {
    width: 50%
}

@media screen and (max-width:1000px) {
    .Field--inline--radio span {
        width: 100%;
        margin-bottom: 10px
    }
}

.Field--inline--radio .Field-errorText {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px
}

.Field-notes {
    font-style: italic;
    color: #999;
    margin-bottom: 5px;
    margin-left: 2px
}

.Figure {
    width: 100%
}

.Figure-image {
    width: 100%;
    display: block
}

.Figure-video {
    height: 0;
    width: 100%;
    padding-bottom: 56.25%;
    position: relative
}

.Figure-video>:first-child {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%
}

.Figure-caption {
    font-family: nunito sans, Helvetica, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 6px
}

.Icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.Icon svg {
    width: 100%;
    height: 100%;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    fill: currentColor
}

.Icon--inverted {
    color: #fff
}

.Icon--highlight {
    margin-bottom: 10px
}

.Icon--highlight:after {
    content: '';
    display: block;
    padding-bottom: 3px;
    border-bottom: 5px solid #6ae3dd;
    width: 150%;
    -webkit-transform: translateX(-17.5%);
    -ms-transform: translateX(-17.5%);
    transform: translateX(-17.5%)
}

.Icon--circle {
    border-radius: 100%;
    overflow: hidden;
    width: 30px;
    height: 30px;
    padding: 5px;
    background: #ccc
}

.Icon--circle svg {
    width: 80%;
    height: 100%;
    position: relative;
    left: 10%
}

.Panel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    position: relative;
    min-width: 0
}

.Panel--plain {
    box-shadow: none
}

.Panel--narrow {
    max-width: 1130px
}

.Panel-section {
    padding: 25px;
    width: 100%;
    min-width: 0
}

@media(max-width:440px) {
    .Panel-section {
        padding: 20px 15px
    }
}

.Panel-section+.Panel-section {
    padding-top: 0
}

.Panel-section--lede {
    padding-top: 30px
}

@media screen and (min-width:380px) {
    .Panel-section--lede {
        padding-top: calc(30px + 20 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Panel-section--lede {
        padding-top: 50px
    }
}

.Panel-section--padded {
    padding-bottom: 20px
}

.Panel-section:last-child {
    padding-bottom: 30px
}

@media screen and (min-width:380px) {
    .Panel-section:last-child {
        padding-bottom: calc(30px + 50 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Panel-section:last-child {
        padding-bottom: 80px
    }
}

.Panel-section-aside>*:not(.Splitter)+* {
    margin-top: 25px
}

@media screen and (min-width:380px) {
    .Panel-section-aside>*:not(.Splitter)+* {
        margin-top: calc(25px + 15 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Panel-section-aside>*:not(.Splitter)+* {
        margin-top: 40px
    }
}

.Panel-section+.Panel-section {
    margin-top: 30px
}

@media screen and (min-width:380px) {
    .Panel-section+.Panel-section {
        margin-top: calc(30px + 10 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Panel-section+.Panel-section {
        margin-top: 40px
    }
}

.Panel-section--nopad {
    padding: 0 !important
}

@media(max-width:770px) {
    .Panel {
        margin-left: -15px;
        margin-right: -15px
    }

    .Panel-section-aside .Splitter {
        margin-top: 60px;
        margin-bottom: 60px
    }
}

@media(min-width:771px) {
    .Panel-section-aside .Splitter {
        display: none
    }

    .Panel--pullup,
    .Panel--pullup-more {
        margin-top: -160px;
        margin-top: -40px
    }
}

@media screen and (min-width:771px) and (min-width:380px) {

    .Panel--pullup,
    .Panel--pullup-more {
        margin-top: calc(-40px + -120 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:771px) and (min-width:1440px) {

    .Panel--pullup,
    .Panel--pullup-more {
        margin-top: -160px
    }
}

@media(min-width:771px) {
    .Panel--pullup-more {
        margin-top: -280px
    }

    .Panel-section--twocol {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .Panel-section {
        padding-left: 0;
        padding-right: 0
    }

    .Panel-section--full,
    .Panel-section-main,
    .Panel-section-aside,
    .Panel-section--narrow {
        padding-left: 25px;
        padding-right: 25px
    }

    .Panel-section--narrow {
        max-width: 66.66667%;
        margin-left: auto;
        margin-right: auto
    }

    .Panel-section-main,
    .Panel-section-aside {
        -webkit-flex: 1 1 100%;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%
    }

    .Panel-section--twocol .Panel-section-main {
        padding-right: 12.5px;
        -webkit-flex-basis: 66.66667%;
        -ms-flex-preferred-size: 66.66667%;
        flex-basis: 66.66667%;
        max-width: 66.66667%
    }

    .Panel-section--twocol .Panel-section-aside {
        padding-left: 12.5px;
        -webkit-flex-basis: 33.33333%;
        -ms-flex-preferred-size: 33.33333%;
        flex-basis: 33.33333%;
        max-width: 33.33333%
    }

    .Panel-header {
        padding-top: 20px;
        padding-bottom: 20px
    }
}

.Panel-section--equalwidthcols {
    display: flex;
    justify-content: center;
    align-self: flex-start;
    flex-wrap: wrap
}

.Panel-section--equalwidthcols .col {
    padding: 0 25px;
    width: 100%;
    box-sizing: border-box;
    max-width: 500px
}

@media screen and (min-width:771px) and (min-width:380px) {
    .Panel-header {
        padding-top: calc(20px + 20 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:771px) and (min-width:1440px) {
    .Panel-header {
        padding-top: 40px
    }
}

@media screen and (min-width:771px) and (min-width:380px) {
    .Panel-header {
        padding-bottom: calc(20px + 20 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:771px) and (min-width:1440px) {
    .Panel-header {
        padding-bottom: 40px
    }
}

@media(min-width:771px) {
    .Panel-section--padded {
        padding-bottom: 20px
    }
}

@media screen and (min-width:771px) and (min-width:380px) {
    .Panel-section--padded {
        padding-bottom: calc(20px + 60 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:771px) and (min-width:1440px) {
    .Panel-section--padded {
        padding-bottom: 80px
    }
}

@media(min-width:771px) {
    .Panel-section--padded:first-child {
        padding-top: 20px
    }
}

@media screen and (min-width:771px) and (min-width:380px) {
    .Panel-section--padded:first-child {
        padding-top: calc(20px + 60 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:771px) and (min-width:1440px) {
    .Panel-section--padded:first-child {
        padding-top: 80px
    }
}

@media(min-width:771px) {
    .Panel-block--pullout {
        margin-left: -26px
    }
}

@media(min-width:1031px) {

    .Panel-section--full,
    .Panel-section--narrow {
        max-width: 83.33333%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0
    }

    .Panel-section-main,
    .Panel-section-aside {
        padding-left: 25px;
        padding-right: 25px
    }

    .Panel-section--narrow {
        max-width: 50%
    }

    .Panel-section--twocol .Panel-section-main {
        -webkit-flex-basis: 58.33333%;
        -ms-flex-preferred-size: 58.33333%;
        flex-basis: 58.33333%;
        max-width: 58.33333%
    }

    .Panel-section--twocol .Panel-section-aside {
        -webkit-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%
    }

    .Panel-block--pullout {
        margin-left: -19%
    }
}

@media(min-width:1441px) {
    .Panel-section--narrow {
        max-width: 50%
    }
}

.Prose>*:first-child {
    margin-top: 0 !important
}

.Prose>* {
    line-height: 1.5;
    margin: 1.1em 0
}

.Prose>p {
    font-size: 14px;
    line-height: 1.6
}

@media screen and (min-width:380px) {
    .Prose>p {
        font-size: calc(14px + 2 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Prose>p {
        font-size: 16px
    }
}

.Prose.intro-para p:nth-of-type(1)::first-letter {
    font-family: playfair display, Georgia, serif
}

@supports(not (initial-letter:3)) and (not (-webkit-initial-letter:3)) {
    .Prose.intro-para p:nth-of-type(1)::first-letter {
        font-size: 63px;
        float: left;
        line-height: .7;
        margin: 5px 4px 0 0
    }

    @media screen and (min-width:380px) {
        .Prose .lede:nth-of-type(1)::first-letter {
            font-size: calc(63px + 9 * (100vw - 380px)/1060)
        }
    }

    @media screen and (min-width:1440px) {
        .Prose .lede:nth-of-type(1)::first-letter {
            font-size: 72px
        }
    }
}

@supports(initial-letter:3) or (-webkit-initial-letter:3) {
    .Prose .lede:nth-of-type(1)::first-letter {
        -webkit-initial-letter: 3;
        initial-letter: 3
    }
}

.Prose>h1,
.Prose>h2,
.Prose>h3,
.Prose>h4 {
    margin-top: 1.1em;
    margin-bottom: .8em
}

.Prose>h1,
.Prose>h2 {
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    font-size: 19px
}

@media screen and (min-width:380px) {

    .Prose>h1,
    .Prose>h2 {
        font-size: calc(19px + 5 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {

    .Prose>h1,
    .Prose>h2 {
        font-size: 24px
    }
}

.Prose>h1.u-large,
.Prose>h2.u-large {
    font-size: 26px;
    line-height: 1.3
}

@media screen and (min-width:380px) {

    .Prose>h1.u-large,
    .Prose>h2.u-large {
        font-size: calc(26px + 24 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {

    .Prose>h1.u-large,
    .Prose>h2.u-large {
        font-size: 50px
    }
}

.Prose>h3 {
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    font-size: 17px
}

@media screen and (min-width:380px) {
    .Prose>h3 {
        font-size: calc(17px + 3 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Prose>h3 {
        font-size: 20px
    }
}

.Prose>h4 {
    font-family: nunito sans, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 15px
}

@media screen and (min-width:380px) {
    .Prose>h4 {
        font-size: calc(15px + 2 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Prose>h4 {
        font-size: 17px
    }
}

.Prose a {
    color: #0c717a;
    text-decoration: none;
    font-weight: 700
}

.Prose>ol,
.Prose>ul {
    padding-left: .75em;
    margin-top: 1.5em;
    margin-bottom: .8em;
    font-size: 14px
}

@media screen and (min-width:380px) {

    .Prose>ol,
    .Prose>ul {
        font-size: calc(14px + 2 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {

    .Prose>ol,
    .Prose>ul {
        font-size: 16px
    }
}

.Prose>ol {
    counter-reset: prose-counter
}

.Prose>ol>li {
    list-style: none;
    margin-bottom: .4em;
    padding-left: 1.2em;
    position: relative
}

.Prose>ol>li:before {
    content: counter(prose-counter) ". ";
    counter-increment: prose-counter;
    position: absolute;
    left: 0;
    top: 1px;
    font-size: 14px
}

.Prose>ul>li {
    list-style-type: none;
    margin-bottom: .4em;
    position: relative;
    padding-left: 1.2em
}

.Prose>ul>li:before {
    content: "●";
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 13px
}

.Prose>.Figure {
    margin-top: 20px;
    margin-bottom: 20px
}

@media screen and (min-width:380px) {
    .Prose>.Figure {
        margin-top: calc(20px + 20 * (100vw - 380px)/1060);
        margin-bottom: calc(20px + 20 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Prose>.Figure {
        margin-top: 40px;
        margin-bottom: 40px
    }
}

.Prose>.Figure .Figure-image {
    max-width: 100%
}

.Prose .u-float-left {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    clear: left
}

.Prose .u-float-right {
    float: right;
    margin-left: 15px;
    margin-bottom: 15px;
    clear: right
}

.Prose>blockquote {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    font-size: 16px;
    font-weight: 700
}

@media screen and (min-width:380px) {
    .Prose>blockquote {
        font-size: calc(16px + 7 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Prose>blockquote {
        font-size: 23px
    }
}

.Prose>blockquote:before {
    content: '';
    display: block;
    width: 50px;
    height: 6px;
    background-color: #6ae3dd;
    margin-bottom: .7em
}

.Prose>blockquote cite {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    font-style: normal;
    display: block;
    margin-top: 8px
}

.Prose+.Form {
    padding-top: 15px
}

.Prose-featureList li {
    font-style: italic;
    font-family: playfair display, Georgia, serif;
    font-size: 18px
}

.Prose-featureList.no-bullets {
    padding-left: 0
}

.Prose-featureList.no-bullets li:before {
    display: none
}

.Prose-featureList.no-bullets li {
    padding-left: 0
}

.Ribbon {
    width: 160px;
    background: #6ae3dd;
    position: absolute;
    display: block;
    top: 20px;
    left: -40px;
    text-align: center;
    line-height: 40px;
    color: #0c717a;
    font-weight: 700;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    text-transform: uppercase;
    font-family: nunito sans, Helvetica, sans-serif;
    font-size: 16px;
    letter-spacing: 1px
}

.Splitter {
    border: 0;
    height: 1px;
    background: #e4e3ea;
    background: -webkit-gradient(linear, 0 0, 100% 0, from(white), to(white), color-stop(50%, #E4E3EA));
    text-align: center;
    margin: 20px 0;
    position: relative
}

.Splitter:before {
    content: '';
    display: inline-block;
    position: relative;
    top: -20px;
    width: 100px;
    height: 40px;
    background: #fff
}

.Splitter:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: -20px;
    left: calc(50% - 35px);
    width: 70px;
    height: 40px;
    /* background-image:url(/assets/img/bubbles.svg); */
    background-repeat: no-repeat;
    background-size: 100%
}

.StaticMap {
    width: 100%
}

.Address {
    font-family: playfair display, Georgia, serif;
    font-style: italic;
    font-size: 16px;
    text-align: center
}

@media screen and (min-width:380px) {
    .Address {
        font-size: calc(16px + 2 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Address {
        font-size: 18px
    }
}

.Address-icon {
    margin-bottom: 24px
}

.Address p {
    line-height: 1.5;
    margin: 0
}

.Checklist {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 16px;
    -moz-column-gap: 16px;
    column-gap: 16px
}

.Checklist-item {
    font-size: 15px;
    margin-bottom: 8px;
    padding-left: 20px;
    position: relative
}

.Checklist-item:before {
    position: absolute;
    left: 0;
    top: -1px;
    content: '✓';
    font-size: 15px;
    display: inline-block;
    font-family: arial;
    opacity: .5;
    margin-right: 8px
}

.Checklist-item--feature:before {
    color: #0c717a
}

.Checklist-item--product:before {
    color: #eb0a5e
}

@media(max-width:769px) {
    .Checklist {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 16px;
        -moz-column-gap: 16px;
        column-gap: 16px
    }
}

.ContentSection-title {
    font-size: 29px;
    font-weight: 700;
    font-family: playfair display, Georgia, serif;
    margin-bottom: 1em
}

@media screen and (min-width:380px) {
    .ContentSection-title {
        font-size: calc(29px + 10 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .ContentSection-title {
        font-size: 39px
    }
}

.ContentSection-content>div:not(:first-child),
.ContentSection-content>section:not(:first-child),
.ContentSection-content>figure:not(:first-child) {
    margin-top: 30px
}

@media screen and (min-width:380px) {

    .ContentSection-content>div:not(:first-child),
    .ContentSection-content>section:not(:first-child),
    .ContentSection-content>figure:not(:first-child) {
        margin-top: calc(30px + 20 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {

    .ContentSection-content>div:not(:first-child),
    .ContentSection-content>section:not(:first-child),
    .ContentSection-content>figure:not(:first-child) {
        margin-top: 50px
    }
}

.ContentSection-content>div:not(:last-child),
.ContentSection-content>section:not(:last-child),
.ContentSection-content>figure:not(:last-child) {
    margin-bottom: 30px
}

@media screen and (min-width:380px) {

    .ContentSection-content>div:not(:last-child),
    .ContentSection-content>section:not(:last-child),
    .ContentSection-content>figure:not(:last-child) {
        margin-bottom: calc(30px + 20 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {

    .ContentSection-content>div:not(:last-child),
    .ContentSection-content>section:not(:last-child),
    .ContentSection-content>figure:not(:last-child) {
        margin-bottom: 50px
    }
}

.ContentSection+.ContentSection {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #e4e3ea
}

@media screen and (min-width:380px) {
    .ContentSection+.ContentSection {
        margin-top: calc(30px + 30 * (100vw - 380px)/1060);
        padding-top: calc(30px + 30 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .ContentSection+.ContentSection {
        margin-top: 60px;
        padding-top: 60px
    }
}

.FeatureList {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0)
}

.FeatureList-title {
    font-size: 25px;
    font-weight: 700;
    font-family: playfair display, Georgia, serif;
    margin-bottom: .9em
}

@media screen and (min-width:380px) {
    .FeatureList-title {
        font-size: calc(25px + 5 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .FeatureList-title {
        font-size: 30px
    }
}

.FeatureList-toggle {
    display: block;
    margin-top: 25px;
    color: #0c717a;
    font-weight: 700
}

.GlossaryEntry-letter {
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 40px;
    width: 1em;
    text-align: center;
    margin-bottom: .2em;
    margin-top: .5em;
    position: relative
}

@media screen and (min-width:380px) {
    .GlossaryEntry-letter {
        font-size: calc(40px + 36 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .GlossaryEntry-letter {
        font-size: 76px
    }
}

.GlossaryEntry-letter:after {
    content: '';
    display: block;
    margin-top: 10px;
    width: 1em;
    height: 13px;
    background-color: #6ae3dd
}

.GlossaryEntry-back {
    color: #ccc;
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    right: -25px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-decoration: none
}

.GlossaryEntry-item {
    padding: 20px 0 5px;
    border-bottom: 1px solid #e4e3ea
}

.GlossaryEntry-item+.GlossaryEntry-item {
    margin-bottom: 20px
}

.GlossaryEntry-itemTitle {
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 18px;
    margin-bottom: .8em;
    position: relative
}

@media screen and (min-width:380px) {
    .GlossaryEntry-itemTitle {
        font-size: calc(18px + 5 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .GlossaryEntry-itemTitle {
        font-size: 23px
    }
}

.InfoBlock {
    position: relative
}

.InfoBlock-icon {
    display: inline-block;
    margin-right: 25px;
    position: relative;
    top: -9px;
    left: 7px
}

.InfoBlock-title {
    font-size: 23px;
    font-weight: 700;
    font-family: playfair display, Georgia, serif;
    margin-bottom: .9em;
    display: inline-block
}

@media(min-width:1440px) {
    .InfoBlock {
        padding-left: 100px
    }

    .InfoBlock-icon {
        position: absolute;
        left: 32px
    }
}

.PriceInfo-price {
    margin-bottom: 16px
}

.PriceInfo-price-amount {
    font-weight: 700;
    font-size: 30px
}

.PriceInfo-price-qualifier {
    font-size: 14px
}

.PriceInfo-title {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 6px
}

.PriceInfo-details {
    margin-bottom: 1em
}

.PriceInfo-smallPrint,
.PriceInfo-smallPrint small {
    font-size: 12px
}

.Review-quote p {
    font-style: italic;
    margin-bottom: 1rem;
    font-size: 16px
}

.Review-author {
    font-weight: 700;
    font-size: 12px;
    color: #0c717a;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 2px
}

.Review-meta {
    margin-bottom: 4px
}

.Review-meta-item {
    display: inline;
    color: #999;
    font-size: 12px;
    line-height: 1
}

.Review-meta-item+.Review-meta-item {
    margin-left: 4px
}

.Review-meta-item+.Review-meta-item:before {
    content: '•';
    color: #ccc;
    display: inline-block;
    margin-right: 7px
}

.Review-voted {
    font-size: 12px
}

.Review-voted strong+strong {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #333
}

.Review+.Review {
    margin-top: 30px
}

.ShortList-title {
    width: 100%;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    font-size: 19px;
    margin: 1em 0
}

@media screen and (min-width:380px) {
    .ShortList-title {
        font-size: calc(19px + 5 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .ShortList-title {
        font-size: 24px
    }
}

.ShortList-groupTitle {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 4px;
    font-family: nunito sans, Helvetica, sans-serif;
    font-weight: 700
}

.ShortList-group {
    margin-bottom: 25px
}

@media(min-width:1030px) {
    .ShortList {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .ShortList-group {
        width: 50%
    }

    .ShortList-group li {
        max-width: 90%
    }
}

.SpaSpy {
    border-top: 1px solid #cfcfcf;
    background-color: #f5f8f8;
    padding-top: 15px
}

.SpaSpy+.SpaSpy {
    margin-top: 25px
}

@media screen and (min-width:380px) {
    .SpaSpy+.SpaSpy {
        margin-top: calc(25px + 15 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SpaSpy+.SpaSpy {
        margin-top: 40px
    }
}

.SpaSpy-inner {
    padding: 0 20px;
    margin: 0 auto
}

.SpaSpy-inner>* {
    max-width: 700px;
    margin: 0 auto
}

.SpaSpy-header {
    margin-bottom: 30px
}

.SpaSpy-meta-item {
    margin-bottom: 5px
}

.SpaSpy-meta-label {
    display: inline-block;
    width: 100px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #777
}

.SpaSpy-meta-value {
    display: inline-block
}

.SpaSpy-meta-title {
    font-size: 25px;
    font-weight: 700;
    font-family: playfair display, Georgia, serif
}

.SpaSpy .Avatar {
    margin-bottom: 10px
}

.SpaSpy-likes:last-child {
    padding-bottom: 25px
}

@media screen and (min-width:380px) {
    .SpaSpy-likes:last-child {
        padding-bottom: calc(25px + 15 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SpaSpy-likes:last-child {
        padding-bottom: 40px
    }
}

.SpaSpy-likes-title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #777;
    margin-bottom: 8px
}

.SpaSpy-detail {
    padding-bottom: 25px;
    transition: height .2s ease
}

@media screen and (min-width:380px) {
    .SpaSpy-detail {
        padding-bottom: calc(25px + 15 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SpaSpy-detail {
        padding-bottom: 40px
    }
}

.SpaSpy-detail-title {
    padding-top: 20px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    border-bottom: 1px solid #cfcfcf;
    margin-bottom: 16px;
    padding-bottom: 5px
}

@media screen and (min-width:380px) {
    .SpaSpy-detail-title {
        padding-top: calc(20px + 10 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SpaSpy-detail-title {
        padding-top: 30px
    }
}

.SpaSpy-detail-list li {
    font-size: 14px;
    margin-bottom: 5px
}

.SpaSpy-detail-list a {
    color: #0c717a;
    font-weight: 400;
    text-decoration: underline
}

.SpaSpy-footer {
    border-top: 1px solid #cfcfcf;
    padding: 16px 0;
    text-align: center;
    display: none
}

[data-js=yes] .SpaSpy-footer {
    display: block
}

.SpaSpy--embedded {
    border: 1px solid #cfcfcf
}

.SpaSpy--embedded .SpaSpy-inner>* {
    max-width: none;
    margin: 0 auto
}

.SpaSpy--embedded .SpaSpy-inner {
    max-width: none
}

@media(min-width:441px) {
    .SpaSpy {
        padding-top: 3%
    }

    .SpaSpy-header {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .SpaSpy-meta {
        margin-right: auto
    }
}

@media(min-width:771px) {
    .SpaSpy-inner {
        max-width: 80%
    }

    .SpaSpy-likes {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }

    .SpaSpy-likes-column {
        -webkit-flex: 0 0 calc(50% - 10px);
        -ms-flex: 0 0 calc(50% - 10px);
        flex: 0 0 calc(50% - 10px)
    }

    .SpaSpy-likes-column:first-child {
        padding-right: 10px
    }

    .SpaSpy-likes-column:last-child {
        padding-left: 10px
    }

    .SpaSpy-detail-list {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 20px;
        -moz-column-gap: 20px;
        column-gap: 20px
    }

    .SpaSpy--embedded .SpaSpy-inner {
        padding-left: 26px;
        padding-right: 26px
    }
}

@media(min-width:1031px) {
    .SpaSpy-detail-list a {
        font-size: 16px
    }

    .SpaSpy--embedded .SpaSpy-inner {
        padding-right: 16%;
        padding-left: 16%
    }
}

.FieldSet {
    margin-bottom: 20px
}

@media screen and (min-width:380px) {
    .FieldSet {
        margin-bottom: calc(20px + 25 * (100vw - 380px)/1060)
    }

    .FieldSet.FieldSet-short {
        margin-bottom: 15px
    }
}

@media screen and (min-width:1440px) {
    .FieldSet {
        margin-bottom: 45px
    }

    .FieldSet.FieldSet-short {
        margin-bottom: 15px
    }


}

.FieldSet-legend {
    font-weight: 700;
    width: 100%;
    padding: 0;
    display: block;
    margin-bottom: 20px;
    color: #212121
}

.FieldSet-legend--standout {
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 19px;
    margin: 0 0 .5em
}

@media screen and (min-width:380px) {
    .FieldSet-legend--standout {
        font-size: calc(19px + 4 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .FieldSet-legend--standout {
        font-size: 23px
    }
}

.FieldSet-group>* {
    margin-bottom: 20px
}

.FieldSet>* {
    margin-bottom: 20px
}

.FieldSet .Field--checkbox,
.FieldSet .Field--radio {
    margin-bottom: 10px
}

.FieldSet .Field--radio+*:not(.Field--radio):not(.Field--checkbox) {
    margin-top: 20px
}

@media(min-width:770px) {
    .FieldSet-multi {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }

    .FieldSet-multi .Field {
        margin-top: 0;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        margin-bottom: 0
    }

    .FieldSet-multi .Field+.Field {
        margin-left: 15px
    }
}

.FieldSet .FieldSet-multi--stretch .Field {
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%
}

.FieldSet>*:last-child {
    margin-bottom: 0
}

.Form {
    margin: 0 auto
}

.Form-title {
    font-size: 20px;
    margin-bottom: 1.2em;
    font-weight: 700;
    font-family: playfair display, Georgia, serif
}

@media screen and (min-width:380px) {
    .Form-title {
        font-size: calc(20px + 10 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Form-title {
        font-size: 30px
    }
}

.Form-content {
    padding-top: 1px
}

.Form-helplink {
    color: #0c717a;
    margin: 2em 0 1em;
    text-align: center;
    font-size: 15px
}

.Form-footer {
    margin-top: 20px;
    padding-bottom: 20px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media screen and (min-width:380px) {
    .Form-footer {
        margin-top: calc(20px + 25 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Form-footer {
        margin-top: 45px
    }
}

.Form-footer .Button {
    -webkit-flex: 0 1 48%;
    -ms-flex: 0 1 48%;
    flex: 0 1 48%
}

.Form-footer .Button--full {
    -webkit-flex: 0 1 100%;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%
}

.Form--narrow {
    max-width: 500px
}

.Form--fullwidth .Field {
    max-width: 100%
}

.AlertMessage {
    font-weight: 700
}

.AlertMessage a {
    color: currentColor
}

.AlertMessage--block {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #eeee;
    margin-bottom: 1rem
}

.AlertMessage--block.AlertMessage--error {
    background-color: rgba(203, 32, 39, .05);
    border: 1px solid #cb2027
}

.AlertMessage--block.AlertMessage--warning {
    background-color: rgba(255, 165, 0, .05);
    border: 1px solid orange
}

.AlertMessage--block.AlertMessage--success {
    background-color: rgba(0, 128, 0, .05);
    border: 1px solid green
}

.AlertMessage--success {
    color: green
}

.AlertMessage--warning {
    color: orange
}

.AlertMessage--error {
    color: #cb2027
}

.Carousel {
    max-height: 700px;
    overflow: hidden
}

.Carousel-inner {
    height: 0;
    width: 100%;
    padding-bottom: 48.61111%;
    position: relative;
    position: relative
}

.Carousel-inner>:first-child {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%
}

.Carousel-items {
    height: 100%;
    max-height: 700px
}

.Carousel .flickity-viewport {
    position: absolute;
    width: 100%;
    height: 100%
}

.Carousel .flickity-slider {
    width: 100%;
    height: 100%;
    position: relative
}

.Carousel .flickity-prev-next-button {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 35px;
    color: #000;
    padding: 12px 8px;
    background: rgba(255, 255, 255, .4);
    opacity: .7;
    transition: opacity .3s ease
}

@media(min-width:770px) {
    .Carousel .flickity-prev-next-button {
        top: calc(50% - 50px)
    }
}

.Carousel:hover .flickity-prev-next-button {
    opacity: 1
}

.Carousel .flickity-prev-next-button.previous {
    left: 0
}

.Carousel .flickity-prev-next-button.next {
    right: 0
}

.Carousel .flickity-page-dots {
    position: absolute;
    left: 50%;
    bottom: 10px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

.Carousel .flickity-page-dots .dot {
    cursor: pointer;
    display: inline-block;
    width: 11px;
    height: 11px;
    border: 1px solid #fff;
    border-radius: 100%;
    margin: 0 3px;
    box-shadow: 0 0 2px rgba(0, 0, 0, .2)
}

.Carousel .flickity-page-dots .dot.is-selected {
    background-color: #fff
}

.Carousel-inner .Carousel-item {
    height: 100%;
    width: 100%
}

.Carousel-inner .Carousel-item img {
    width: 100%
}

@media(max-width:439px) {
    .Carousel .flickity-page-dots {
        display: none
    }
}

@media(min-width:770px) {
    .Carousel .flickity-page-dots {
        bottom: 50px
    }
}

@media screen and (min-width:770px) and (min-width:380px) {
    .Carousel .flickity-page-dots {
        bottom: calc(50px + 120 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:770px) and (min-width:1440px) {
    .Carousel .flickity-page-dots {
        bottom: 170px
    }
}

@media(min-width:1440px) {
    .Carousel-inner .Carousel-item {
        bottom: calc(((100vw * 9/16) - 700px)/2)
    }
}

.PageHead {
    text-align: center;
    margin-left: auto;
    margin-right: auto
}

.PageHead-title {
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 40px
}

@media screen and (min-width:380px) {
    .PageHead-title {
        font-size: calc(40px + 36 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .PageHead-title {
        font-size: 76px
    }
}

.PageHead-title:first-child {
    margin-top: .5em
}

.PageHead-title+.Metadata {
    margin-top: 30px;
    margin-bottom: 20px
}

@media screen and (min-width:380px) {
    .PageHead-title+.Metadata {
        margin-top: calc(30px + 20 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .PageHead-title+.Metadata {
        margin-top: 50px
    }
}

@media(max-width:1029px) {
    .PageHead-title+.Metadata {
        margin-bottom: 30px
    }
}

.Metadata--article-full+.PageHead-title {
    margin-top: 2%
}

.PageHead .Metadata--article-full:first-child {
    margin-top: 2%
}

.PageHead .Metadata.Metadata--article-full {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.PageHead-rating {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    margin-bottom: 15px
}

.PageHead-rating:after {
    content: '';
    display: block;
    height: 6px;
    width: 80%;
    background-color: #6ae3dd;
    margin: 5px auto
}

.PageHead-intro {
    margin-top: .6em;
    font-family: playfair display, Georgia, serif;
    font-size: 19px
}

@media screen and (min-width:380px) {
    .PageHead-intro {
        font-size: calc(19px + 6 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .PageHead-intro {
        font-size: 25px
    }
}

.PageHead-intro+.Splitter,
.PageHead-title+.Splitter,
.Splitter+.PageHead-intro {
    margin-top: 30px
}

@media screen and (min-width:380px) {

    .PageHead-intro+.Splitter,
    .PageHead-title+.Splitter,
    .Splitter+.PageHead-intro {
        margin-top: calc(30px + 20 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {

    .PageHead-intro+.Splitter,
    .PageHead-title+.Splitter,
    .Splitter+.PageHead-intro {
        margin-top: 50px
    }
}

.PageHead-intro,
.PageHead-title {
    margin-left: auto;
    margin-right: auto
}

@media(min-width:441px) {

    .PageHead-intro,
    .PageHead-title {
        max-width: 83.33333%
    }
}

@media(min-width:771px) {

    .PageHead-intro,
    .PageHead-title {
        max-width: 66.66667%
    }
}

.PageHead--full,
.PageHead--full .PageHead-intro,
.PageHead--full .PageHead-title {
    width: 100%;
    max-width: 100%;
    text-align: left
}

.PageHead--full .Metadata.Metadata--article-full {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.SectionHead {
    text-align: center;
    margin-left: auto;
    margin-right: auto
}

.SectionHead-inner {
    background-position: center center
}

.SectionHead-title {
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    line-height: 1.1;
    font-size: 40px
}

@media screen and (min-width:380px) {
    .SectionHead-title {
        font-size: calc(40px + 36 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SectionHead-title {
        font-size: 76px
    }
}

.SectionHead-title em {
    font-size: .2em
}

.SectionHead-intro {
    margin-top: .6em;
    font-family: nunito sans, Helvetica, sans-serif;
    font-size: 18px
}

@media screen and (min-width:380px) {
    .SectionHead-intro {
        font-size: calc(18px + 2 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SectionHead-intro {
        font-size: 20px
    }
}

.SectionHead-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: 700px
}

.SectionHead-search {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 30px
}

.SectionHead-search .Field {
    min-width: 250px;
    margin-right: 16px;
    margin-bottom: 16px
}

.SectionHead-search label {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important
}

.SectionHead-search .Button {
    display: block;
    margin-bottom: 16px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

@media(min-width:441px) {
    .SectionHead-search {
        max-width: 83.33333%
    }
}

@media(min-width:771px) {
    .SectionHead-search {
        padding-top: 3.1%
    }
}

@media(min-width:1031px) {
    .SectionHead-search {
        max-width: 66.66667%
    }
}

.SectionHead-smallprint {
    color: #fff;
    font-size: 16px;
    padding-top: 1%;
    margin: 0 auto
}

@media(min-width:441px) {
    .SectionHead-smallprint {
        max-width: 83.33333%
    }
}

@media(min-width:771px) {
    .SectionHead-smallprint {
        max-width: 66.66667%
    }
}

@media(min-width:1031px) {
    .SectionHead-smallprint {
        max-width: 50%
    }
}

.SectionHead--banner {
    max-height: 740px;
    overflow: hidden
}

@media all and (max-height:800px) {
    .SectionHead--banner {
        max-height: 540px
    }
}

.SectionHead--banner .SectionHead-inner {
    height: 0;
    width: 100%;
    padding-bottom: 48.61111%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.SectionHead--banner .SectionHead-inner>:first-child {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%
}

.SectionHead--banner-shallow {
    max-height: 320px;
    min-height: 150px;
    overflow: hidden;
    padding-top: 0 !important
}

.SectionHead--banner-shallow .SectionHead-inner {
    height: 0;
    width: 100%;
    padding-bottom: 22.22222%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 150px
}

.SectionHead--banner-shallow .SectionHead-inner>:first-child {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%
}

.SectionHead--banner .SectionHead-content {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important
}

.SectionHead--content-banner .SectionHead-inner {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    padding-top: 15px;
    padding-bottom: 15px
}

@media(max-width:1439px) {
    .SectionHead--content-banner .SectionHead-inner {
        min-height: 50vw
    }

    .SectionHead--content-banner.SectionHead--shorter-banner .SectionHead-inner {
        min-height: 40vw
    }
}

@media(min-width:1440px) {
    .SectionHead--content-banner .SectionHead-inner {
        min-height: 700px
    }

    .SectionHead--content-banner.SectionHead--shorter-banner .SectionHead-inner {
        min-height: 650px
    }
}

.SectionHead--content-banner .SectionHead-inner.static-head {
    display: block;
    padding-top: 15px;
    min-height: auto;
    background-color: #fff
}

.SectionHead--content-banner .SectionHead-inner.static-head img {
    display: block;
    margin: auto;
    max-width: 100%
}

.SectionHead--content-banner .SectionHead-title,
.SectionHead--content-banner .SectionHead-intro {
    color: #fff;
    margin-left: auto;
    margin-right: auto
}

@media(min-width:441px) {

    .SectionHead--content-banner .SectionHead-title,
    .SectionHead--content-banner .SectionHead-intro {
        max-width: 83.33333%
    }
}

@media(min-width:771px) {

    .SectionHead--content-banner .SectionHead-title,
    .SectionHead--content-banner .SectionHead-intro {
        max-width: 66.66667%
    }
}

@media(min-width:1031px) {

    .SectionHead--content-banner .SectionHead-title,
    .SectionHead--content-banner .SectionHead-intro {
        max-width: 50%
    }
}

@media all and (max-height:800px) {
    .SectionHead--carousel {
        max-height: 540px
    }
}

.SectionHead--compact.SectionHead--banner {
    max-height: 320px;
    overflow: hidden;
    height: 0;
    width: 100%;
    padding-bottom: 22.22222%;
    position: relative
}

.SectionHead--compact.SectionHead--banner>:first-child {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%
}

.SectionHead--blog {
    padding-top: 30px;
    /* background:url(/assets/img/bg-blog-header.png) no-repeat 50% 0; */
    padding-bottom: 30px;
    margin-bottom: 0
}

@media screen and (min-width:380px) {
    .SectionHead--blog {
        padding-top: calc(30px + 70 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SectionHead--blog {
        padding-top: 100px
    }
}

.SectionHead--blog .SectionHead-title {
    /* font-family:brusher,Georgia,serif; */
    line-height: 1.2;
    font-size: 40px
}

@media screen and (min-width:380px) {
    .SectionHead--blog .SectionHead-title {
        font-size: calc(40px + 36 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SectionHead--blog .SectionHead-title {
        font-size: 76px
    }
}

.CategoryPicker {
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    font-family: nunito sans, Helvetica, sans-serif;
    padding-top: 10px;
    text-align: center
}

.CategoryPicker-selected {
    font-weight: 700;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 20px;
    cursor: pointer;
    display: inline-block
}

.CategoryPicker-selected:after {
    content: '▾';
    font-family: arial;
    display: inline-block;
    margin-left: 10px
}

.CategoryPicker-panel {
    display: none;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 10;
    padding-top: 14px
}

.CategoryPicker-panel:before {
    content: '';
    display: block;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2
}

.CategoryPicker-panel:after {
    content: '';
    display: block;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 16px solid #ddd;
    display: block;
    position: absolute;
    top: -2px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1
}

.CategoryPicker.is-open .CategoryPicker-panel {
    display: block
}

.CategoryPicker-options {
    background: #fff;
    padding: 20px 40px;
    border: 1px solid #ddd;
    border-radius: 5px
}

.CategoryPicker-link {
    font-size: 20px;
    color: #818181;
    font-weight: 700;
    text-decoration: none;
    padding: 5px 0;
    display: block;
    white-space: nowrap
}

.CategoryPicker-link:hover {
    color: #212121
}

.Cta .Button {
    display: block;
    width: 100%
}

.Cta .Button+.Button {
    margin-top: 20px
}

.Cta.is-pinned {
    display: none
}

.Cta.is-pinned .Cta-inner {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%
}

@media(min-width:770px) {
    .Cta--inline .Cta-inner {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .Cta--inline .Cta-inner .Button {
        width: 48%;
        margin: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto
    }
}

@media(max-width:770px) {
    [data-js=yes] .Cta[data-component] {
        display: none
    }

    [data-js=yes] .Cta.is-pinned {
        border-top: 1px solid #e4e3ea;
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 15px 20px;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        z-index: 100;
        -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
        transform: translateY(100%);
        transition: all .3s ease-in-out
    }

    [data-js=yes] .Cta.is-pinned .Button {
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        margin-top: 0
    }

    [data-js=yes] .Cta.is-pinned .Button+.Button {
        margin-left: 20px
    }

    [data-js=yes] .Cta.is-pinned.in-view {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}

.GlossaryNav {
    width: 100%;
    overflow: auto;
    border: 1px solid #ccc;
    margin: 0 auto
}

.GlossaryNav::-webkit-scrollbar {
    display: none
}

.GlossaryNav-letters {
    width: 780px;
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto
}

.GlossaryNav-letterLink {
    display: block;
    float: left;
    height: 30px;
    width: 30px;
    text-decoration: none;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
    font-weight: 700;
    color: #0c717a
}

.ListingSort .Field {
    display: inline-block
}

[data-js=yes] .ListingSort .Button {
    display: none
}

.Map {
    display: block;
    width: 100%;
    height: 0;
    width: 100%;
    padding-bottom: 70%;
    position: relative;
    position: relative
}

.Map>:first-child {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%
}

.Map .Map-target {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #eee
}

.Map-placeholder {
    font-size: 16px;
    opacity: .5
}

.Map .infoBox>img {
    position: absolute !important;
    right: 5px !important;
    top: 5px !important
}

.Map-marker-info img {
    width: 100%;
    display: block
}

.Map-marker-content {
    padding: 10px;
    position: relative;
    background-color: #fff
}

.Map-marker-arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #fff;
    display: block;
    position: relative;
    margin-left: 83px
}

.Map-marker-title {
    font-size: 17px;
    line-height: 1.1;
    margin-bottom: 15px
}

.Map-marker-title a {
    text-decoration: none
}

.Map-marker-recommended {
    font-weight: 700;
    display: block;
    font-size: 13px;
    opacity: .5;
    margin-bottom: 5px
}

.Map-marker-offers {
    display: block;
    font-weight: 700;
    font-size: 14px
}

.Map-marker-offers:before {
    content: '★';
    font-size: 15px;
    margin-right: 4px
}

[data-js=yes] .modal-hide {
    display: none
}

.modal {
    display: inline-block;
    vertical-align: top;
    position: relative;
    z-index: 2000;
    max-width: 900px;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    text-align: left;
    height: 100%;
    padding: 45px 15px 30px;
    transition: opacity .2s ease-in-out
}

@media(min-width:440px) {
    .modal {
        height: auto;
        display: inline-block;
        vertical-align: middle;
        padding: 45px 7vw 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .5)
    }
}

.blocker {
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 1000;
    box-sizing: border-box;
    text-align: center
}

.blocker:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -.05em
}

@media(min-width:440px) {
    .blocker {
        padding: 45px 20px 20px;
        background-color: rgba(12, 113, 122, .8)
    }
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 11px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none
}

.close-modal:before {
    content: "X";
    font-size: 18px;
    margin-right: 5px;
    text-decoration: none;
    position: relative;
    top: 1px
}

@media(min-width:440px) {
    .close-modal {
        color: #fff;
        top: -39px;
        right: 0
    }

    .close-modal:before {
        top: 3px;
        font-size: 24px
    }
}

.ArticlePagination {
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.ArticlePagination-items {
    margin: 0 auto;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 2
}

.ArticlePagination a:hover {
    text-decoration: underline
}

.ArticlePagination-item {
    margin: 0;
    border: 1px solid #e4e3ea;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.ArticlePagination-item>* {
    font-size: 16px;
    width: 2.3em;
    padding: .5em 0 .6em;
    text-align: center;
    display: block;
    vertical-align: middle;
    text-decoration: none;
    color: #0c717a;
    font-weight: 700
}

.ArticlePagination-item.is-current>* {
    background-color: #efefef
}

.ArticlePagination-nav>* {
    font-size: 12px;
    font-family: arial;
    position: relative;
    display: block;
    padding: .9em 0;
    width: 3.2em
}

.ArticlePagination-nav--disabled {
    pointer-events: none;
    color: #ccc
}

.ArticlePagination-jump a {
    padding: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #0c717a;
    text-decoration: none
}

.ArticlePagination-jump+.ArticlePagination-item,
.ArticlePagination-item+.ArticlePagination-jump {
    margin-left: 10px;
    margin-left: 10px
}

.ArticlePagination-item:not(.is-current):not(.ArticlePagination-break)>*:hover {
    background-color: #fff
}

.ArticlePagination-item+.ArticlePagination-item {
    border-left: 0
}

*+.ArticlePagination {
    margin-top: 20px
}

@media(max-width:769px) {

    .ArticlePagination-page,
    .ArticlePagination-break {
        display: none
    }

    .ArticlePagination-page.is-current {
        display: block
    }
}

.Signup {
    padding: 20px;
    background: #fff;
    border: 1px solid #cfcfcf;
    border-radius: 5px
}

.Signup-title {
    font-size: 25px;
    font-weight: 700;
    font-family: playfair display, Georgia, serif;
    margin-bottom: 10px
}

.Signup-form {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.Signup-form label {
    font-weight: 400
}

.Signup-form .Field {
    margin-top: 15px;
    width: 100%
}

.Signup-form .Button {
    margin-top: 15px;
    display: block;
    width: 100%;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0
}

@media(min-width:441px) {
    .Signup {
        padding: 25px
    }

    .Signup-form .Field {
        margin-right: 20px;
        width: calc(50% - 10px)
    }

    .Signup-form .Field:nth-child(2n+2) {
        margin-right: 0
    }

    .Signup-form .Button {
        width: calc(50% - 10px)
    }
}

@media(min-width:771px) {
    .Signup {
        padding: 25px 30px
    }

    .Signup-form {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .Signup-form .Field {
        width: auto;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }

    .Signup-form .Field:nth-child(2n+2) {
        margin-right: 20px
    }

    .Signup-form .Button {
        margin-left: auto;
        width: 100%;
        max-width: 200px
    }
}

.CardSet-title {
    text-align: center;
    font-size: 22px;
    line-height: 1.2;
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    margin-bottom: 1em
}

@media screen and (min-width:380px) {
    .CardSet-title {
        font-size: calc(22px + 10 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .CardSet-title {
        font-size: 32px
    }
}

.CardSet-cards {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: calc(100% + 25px);
    margin-left: -25px;
    margin-bottom: -25px
}

.CardSet .Card {
    max-width: none
}

.CardSet-card {
    box-sizing: border-box;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    padding-bottom: 25px;
    padding-left: 25px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.CardSet--pair .CardSet-cards,
.CardSet--thirds .CardSet-cards {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media(min-width:441px) {
    .CardSet-card {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%
    }

    .CardSet--pair .CardSet-card {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%
    }
}

@media(min-width:541px) {

    .CardSet--pair .CardSet-card,
    .CardSet--halves .CardSet-card {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%
    }
}

@media(min-width:1031px) {
    .CardSet--pair .CardSet-card {
        -webkit-flex-basis: 40%;
        -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
        max-width: 40%
    }

    .CardSet--halves .CardSet-card {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%
    }

    .CardSet--thirds .CardSet-card {
        -webkit-flex-basis: 33%;
        -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
        max-width: 33%
    }

    .CardSet--fourths .CardSet-card {
        -webkit-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%
    }

    .CardSet--fifths .CardSet-card {
        -webkit-flex-basis: 20%;
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%
    }
}

.CardSet-card.CardSet-card--full {
    width: 100%;
    max-width: none;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%
}

.CardSet-card.CardSet-card--full .Card {
    max-width: none
}

.Card {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    max-width: 380px
}

.Card-image {
    display: block
}

.Card-image img {
    width: 100%;
    display: block
}

.Card-body {
    position: relative
}

.Card-offers {
    display: inline-block;
    padding: 5px 8px;
    background-color: #6ae3dd;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    font-size: 12px;
    font-weight: 700;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%)
}

.Card-location {
    float: left;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 12px;
    text-transform: uppercase
}

.Card-location:before {
    content: '';
    height: 6px;
    width: 30px;
    background-color: #6ae3dd;
    display: block;
    margin-bottom: 4px
}

.Card-image {
    height: 0;
    width: 100%;
    padding-bottom: 56.25%;
    position: relative
}

.Card-image>:first-child {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%
}

.Card-rating {
    float: right;
    width: 60px;
    height: 60px
}

.Card-recommended {
    display: block;
    clear: both;
    font-size: 12px;
    font-weight: 700
}

.Card--spa .Card-title,
.Card--offer .Card-title,
.Card--gift-offer .Card-title,
.Card--article .Card-title,
.Card--post .Card-title,
.Card--competition .Card-title {
    padding-top: 4px;
    clear: both;
    font-size: 19px;
    line-height: 1.2;
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    margin-bottom: .8em
}

@media screen and (min-width:380px) {

    .Card--spa .Card-title,
    .Card--offer .Card-title,
    .Card--gift-offer .Card-title,
    .Card--article .Card-title,
    .Card--post .Card-title,
    .Card--competition .Card-title {
        font-size: calc(19px + 6 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {

    .Card--spa .Card-title,
    .Card--offer .Card-title,
    .Card--gift-offer .Card-title,
    .Card--article .Card-title,
    .Card--post .Card-title,
    .Card--competition .Card-title {
        font-size: 25px
    }
}

.Card--spa .Card-title a,
.Card--offer .Card-title a,
.Card--gift-offer .Card-title a,
.Card--article .Card-title a,
.Card--post .Card-title a,
.Card--competition .Card-title a {
    text-decoration: none
}

.Card-description {
    font-size: 14px;
    margin: 0 0 .9em
}

.Card-description p+p {
    margin-top: .8em
}

.Card-price {
    font-size: 14px
}

.Card-price-amount {
    font-size: 30px;
    font-weight: 700;
    line-height: 1
}

.Card-price-qualifier {
    white-space: nowrap
}

.Card-more {
    margin-top: 14px
}

.Card-label {
    background-color: #0c717a;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    display: inline-block;
    padding: .5em 1em
}

.Card--spa.Card--standard .Card-body,
.Card--offer.Card--standard .Card-body,
.Card--article.Card--standard .Card-body,
.Card--post.Card--standard .Card-body,
.Card--competition.Card--standard .Card-body {
    z-index: 2;
    background-color: #fff;
    position: relative;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .3);
    padding: 12px
}

.Card--spa.Card--standard .Card-body,
.Card--offer.Card--standard .Card-body,
.Card--article.Card--standard .Card-body,
.Card--competition.Card--standard .Card-body {
    margin: -30px 10px 0
}

.Card--offer.Card--featured,
.Card--spa.Card--featured,
.Card--competition.Card--featured {
    padding-top: 25px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto
}

.Card--offer.Card--featured .Card-inner,
.Card--spa.Card--featured .Card-inner,
.Card--competition.Card--featured .Card-inner {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .3);
    padding: 0 12px 12px;
    position: relative;
    background-color: #fff
}

.Card--offer.Card--featured .Card-body,
.Card--spa.Card--featured .Card-body,
.Card--competition.Card--featured .Card-body {
    margin-top: -13px
}

.Card--offer.Card--featured .Card-offers,
.Card--spa.Card--featured .Card-offers,
.Card--competition.Card--featured .Card-offers {
    top: -12px
}

.Card--offer.Card--featured .Card-image,
.Card--spa.Card--featured .Card-image,
.Card--competition.Card--featured .Card-image {
    position: relative;
    top: -25px
}

.Card--offer.Card--featured .Metadata-item,
.Card--spa.Card--featured .Metadata-item,
.Card--competition.Card--featured .Metadata-item {
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.Card--offer.Card--featured .Card-inner:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    height: 10px;
    -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 3px 1px);
    background: linear-gradient(45deg, transparent 33.333%, #fff 33.333%, #fff 66.667%, transparent 66.667%), linear-gradient(-45deg, transparent 33.333%, #fff 33.333%, #fff 66.667%, transparent 66.667%);
    background-size: 8px 20px;
    background-position: 0 -10px
}

.Card--related-article {
    background-color: #fff;
    position: relative;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .3);
    padding: 12px;
    text-align: center
}

.Card--related-article .Card-title {
    margin-top: 15px;
    font-size: 16px;
    margin-bottom: 12px
}

.Card--related-article .Card-title a {
    text-decoration: none;
    color: #0c717a;
    font-weight: 700
}

.Card--gift-offer {
    background-color: #fff;
    position: relative;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .3);
    padding: 20px;
    max-width: 800px
}

.Card--gift-offer .Card-title-wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 10px;
    padding-top: 20px
}

.Card--gift-offer .Card-title {
    margin-right: 20px
}

.Card--gift-offer .Card-price {
    margin-left: auto
}

.Card--post .Metadata:first-child,
.Card--article .Metadata:first-child {
    margin-top: 10px
}

.Card--post .Metadata+.Card-title,
.Card--article .Metadata+.Card-title {
    margin-top: 5px
}

.Card--post .Metadata:last-child,
.Card--article .Metadata:last-child {
    margin-top: 20px;
    margin-bottom: 5px
}

.Card--post.Card--featured {
    max-width: 900px;
    margin-bottom: 60px
}

.Card--post.Card--featured .Card-inner {
    position: relative
}

.Card--post.Card--featured .Card-body {
    position: absolute;
    min-height: 120px;
    bottom: -50px;
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
    background: #fff;
    padding: 20px 140px 20px 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
    /* background-image:url(/assets/img/featured-post.svg); */
    background-position: calc(100% - 30px) 100%;
    background-repeat: no-repeat
}

@media screen and (min-width:380px) {
    .Card--post.Card--featured .Card-body {
        padding-top: calc(20px + 10 * (100vw - 380px)/1060);
        padding-bottom: calc(20px + 10 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Card--post.Card--featured .Card-body {
        padding-top: 30px;
        padding-bottom: 30px
    }
}

.Card--post.Card--featured .Card-title {
    font-size: 20px;
    margin-bottom: .4em
}

@media screen and (min-width:380px) {
    .Card--post.Card--featured .Card-title {
        font-size: calc(20px + 30 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Card--post.Card--featured .Card-title {
        font-size: 50px
    }
}

@media(max-width:770px) {
    .Card--post.Card--featured {
        margin-bottom: 0
    }

    .Card--post.Card--featured .Card-body {
        left: 0;
        right: 0;
        bottom: 0;
        margin-top: -30px;
        width: 100%;
        position: relative;
        padding-right: 80px;
        background-size: 59px 78px;
        background-position: calc(100% - 10px) 100%
    }
}

@media(max-width:440px) {
    .Card--post.Card--featured .Card-body {
        background: 0 0;
        padding: 15px;
        margin-top: 0
    }
}

.Card--article.Card--featured {
    max-width: 900px
}

.Card--article.Card--featured .Card-image {
    position: relative
}

.Card--article.Card--featured .Card-image:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.3))
}

.Card--article.Card--featured .Card-title {
    font-size: 20px;
    margin-bottom: .4em
}

@media screen and (min-width:380px) {
    .Card--article.Card--featured .Card-title {
        font-size: calc(20px + 41 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .Card--article.Card--featured .Card-title {
        font-size: 61px
    }
}

.Card--article.Card--featured .Card-inner {
    position: relative
}

.Card--article.Card--featured .Card-body {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: 0 0;
    padding: 10px 5%;
    color: #fff
}

.Card--article.Card--featured .Icon {
    color: #fff;
    fill: #fff
}

.FeaturedCards-title {
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px
}

.FeaturedCards-title:after {
    content: '';
    display: block;
    width: 40px;
    height: 6px;
    margin: 0 auto;
    background-color: #6ae3dd;
    margin-top: 8px
}

@media(min-width:771px) {
    .FeaturedCards-title {
        font-size: 16px;
        margin-bottom: 30px
    }
}

.NoResults {
    text-align: center;
    opacity: .5
}

.NoResults-icon {
    margin-bottom: 60px;
    width: 58px;
    height: 58px
}

.NoResults-title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 4px
}

.NoResults-text {
    font-size: 18px
}

.RelatedContent {
    padding-top: 1px
}

.RelatedContent-title {
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 1em;
    margin-top: 1.2em
}

@media screen and (min-width:380px) {
    .RelatedContent-title {
        font-size: calc(25px + 15 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .RelatedContent-title {
        font-size: 40px
    }
}

.RelatedContent-more {
    margin-top: 20px
}

@media screen and (min-width:380px) {
    .RelatedContent-more {
        margin-top: calc(20px + 20 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .RelatedContent-more {
        margin-top: 40px
    }
}

.RelatedContent-more p a {
    font-weight: 700;
    color: #0c717a;
    text-decoration: none
}

.RelatedContent-more--button {
    margin-top: 25px;
    text-align: center
}

@media screen and (min-width:380px) {
    .RelatedContent-more--button {
        margin-top: calc(25px + 25 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .RelatedContent-more--button {
        margin-top: 50px
    }
}

.RelatedContent--centered .RelatedContent-title {
    text-align: center
}

.RelatedContent--centered .CardSet {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.RelatedContent--blog {
    padding-top: 20px;
    /* background:url(/assets/img/bg-blog-header.png) no-repeat 50% 0; */
    background-size: 465px 264px
}

.RelatedContent--blog .RelatedContent-title {
    /* font-family:brusher,Georgia,serif; */
    line-height: 1.2;
    font-size: 30px
}

@media screen and (min-width:380px) {
    .RelatedContent--blog .RelatedContent-title {
        font-size: calc(30px + 20 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .RelatedContent--blog .RelatedContent-title {
        font-size: 50px
    }
}

.Results-header {
    margin-bottom: 20px
}

.Results-title {
    font-size: 25px;
    font-weight: 700
}

.Results-viewToggle {
    font-size: 14px;
    color: #666
}

.Results-viewToggle a {
    color: #0c717a
}

.Results-filter {
    margin-bottom: 30px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.Results-filter .Field {
    margin-bottom: 15px;
    width: 100%
}

.Results-filter label {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important
}

.Results-filter .Button {
    display: block;
    width: 100%;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0
}

.Results-loader {
    margin-top: 40px;
    text-align: center
}

@media(min-width:441px) {

    .Results-title,
    .Results-viewToggle {
        display: inline-block
    }

    .Results-title {
        margin-right: 12px
    }

    .Results-filter {
        margin-bottom: 40px
    }

    .Results-filter .Field {
        margin-right: 20px;
        width: calc(50% - 10px)
    }

    .Results-filter .Field:nth-child(2n+2) {
        margin-right: 0
    }

    .Results-filter .Button {
        width: calc(50% - 10px)
    }
}

@media(min-width:771px) {
    .Results-filter {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .Results-filter .Field {
        max-width: 300px;
        width: 25%
    }

    .Results-filter .Field:nth-child(2n+2) {
        margin-right: 20px
    }

    .Results-filter .Button {
        margin-left: auto;
        width: 100%;
        max-width: 200px
    }
}

.JumpNav-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 400;
    padding-left: 12px;
    margin-bottom: 17px;
    font-weight: 700;
    font-family: playfair display, Georgia, serif
}

@media(max-width:769px) {
    .JumpNav-items {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 16px;
        -moz-column-gap: 16px;
        column-gap: 16px
    }
}

.JumpNav-item {
    display: block
}

.JumpNav-item+.JumpNav-item {
    border-top: 1px solid #e4e3ea
}

.JumpNav-link {
    line-height: 1.2;
    display: block;
    padding: 10px 0;
    color: #0c717a;
    text-decoration: none;
    font-weight: 700
}

.JumpNav .Icon {
    width: 16px;
    height: 16px;
    margin-right: 5px
}

.JumpNav+.JumpNav {
    margin-top: 25px
}

@media screen and (min-width:380px) {
    .JumpNav+.JumpNav {
        margin-top: calc(25px + 25 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .JumpNav+.JumpNav {
        margin-top: 50px
    }
}

.SidebarNav-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 400;
    padding-left: 12px;
    margin-bottom: 17px;
    font-weight: 700;
    font-family: playfair display, Georgia, serif
}

.SidebarNav-item {
    display: block
}

.SidebarNav-item+.SidebarNav-item {
    border-top: 1px solid #e4e3ea
}

.SidebarNav-link {
    line-height: 1.2;
    display: block;
    padding: 12px;
    color: #0c717a;
    text-decoration: none;
    font-weight: 700
}

.SidebarNav .Icon {
    width: 16px;
    height: 16px;
    margin-right: 5px
}

.SidebarNav+.SidebarNav {
    margin-top: 25px
}

@media screen and (min-width:380px) {
    .SidebarNav+.SidebarNav {
        margin-top: calc(25px + 25 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SidebarNav+.SidebarNav {
        margin-top: 50px
    }
}

.Adslot {
    text-align: center
}

.Adslot-title {
    font-size: 13px;
    text-transform: uppercase;
    color: #bbb;
    margin-bottom: 10px;
    font-weight: 700
}

.Adslot-ad {
    margin: 0 auto;
    width: 100%
}

.Adslot-ad img {
    max-width: 100%
}

.AwardsResult {
    max-width: 900px;
    margin-top: 30px
}

@media screen and (min-width:380px) {
    .AwardsResult {
        margin-top: calc(30px + 30 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .AwardsResult {
        margin-top: 60px
    }
}

.AwardsResult-header {
    width: 100%;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
}

.AwardsResult-title {
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 .6em
}

@media screen and (min-width:380px) {
    .AwardsResult-title {
        font-size: calc(18px + 5 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .AwardsResult-title {
        font-size: 23px
    }
}

.AwardsResult-sponsored {
    color: #6ae3dd;
    font-weight: 700;
    margin-bottom: 10px
}

.AwardsResult-sponsored img {
    margin: 9px 0
}

.AwardsResult-winnerTitle {
    font-size: 16px;
    margin-bottom: 10px
}

.AwardsResult-winnerTitle mark {
    display: inline-block;
    padding: 3px 10px;
    font-weight: 700;
    background-color: #6ae3dd;
    text-transform: uppercase;
    font-size: 15px;
    margin-right: 10px
}

.AwardsResult a {
    color: #0c717a
}

.AwardsResult-winner img {
    margin-bottom: 10px;
    width: 100%
}

.AwardsResult-runnersupTitle {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 16px
}

.AwardsResult-runnersup li {
    margin-bottom: 4px
}

@media(min-width:1030px) {
    .AwardsResult {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .AwardsResult-winner {
        width: 55%
    }

    .AwardsResult-runnersup {
        width: 42%;
        margin-left: auto
    }
}

.BubbleRatingCardSet {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 0 10px
}

.BubbleRatingCardSet-card {
    width: 100%;
    margin-bottom: 20px
}

.BubbleRatingCardSet .BubbleRatingCard {
    height: 100%
}

@media(min-width:440px) {
    .BubbleRatingCardSet-card {
        width: 50%;
        padding: 0 10px
    }
}

@media(min-width:1030px) {
    .BubbleRatingCardSet-card {
        width: 25%
    }
}

.BubbleRatingCard {
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
    padding: 20px 6%;
    max-width: 500px
}

.BubbleRatingCard-img {
    max-width: 140px;
    width: 100%;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px
}

.BubbleRatingCard-rating {
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 19px;
    margin: .8em 0 .5em
}

@media screen and (min-width:380px) {
    .BubbleRatingCard-rating {
        font-size: calc(19px + 6 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .BubbleRatingCard-rating {
        font-size: 25px
    }
}

.BubbleRatingCard-label {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    padding: 5px 8px 3px;
    background-color: #6ae3dd;
    letter-spacing: 1px;
    line-height: 1
}

.BubbleRatingCard-summary {
    font-family: playfair display, Georgia, serif;
    font-style: italic;
    font-size: 16px;
    margin: 1rem 0
}

.BubbleRatingCard-description {
    font-size: 14px
}

.BubbleRatingCard--three .BubbleRatingCard-label {
    background-color: rgba(106, 227, 221, .3)
}

.BubbleRatingCard--four .BubbleRatingCard-label {
    background-color: rgba(106, 227, 221, .6)
}

.BubbleRatingCard--five .BubbleRatingCard-label {
    background-color: rgba(106, 227, 221, .8)
}

.BubbleRatingCard--five-luxury .BubbleRatingCard-label {
    background-color: #6ae3dd
}

.FeaturedIn-items {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around
}

.FeaturedIn-item {
    max-width: 160px;
    min-width: 110px;
    padding: 0 10px 10px
}

.FeaturedIn-item img {
    width: 100%
}

@media(max-width:770px) {
    .FeaturedIn-items {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
}

.MagazineEmbed {
    height: 0;
    width: 100%;
    padding-bottom: 70.6383%;
    position: relative
}

.MagazineEmbed>:first-child {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%
}

.Metadata a {
    color: currentColor;
    text-decoration: none
}

.Metadata--spa-full {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.Metadata--spa-full .Metadata-item {
    text-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative
}

.Metadata--spa-full .Metadata-icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    margin-bottom: 0
}

.Metadata--spa-full .Metadata-label {
    font-size: 12px;
    display: inline;
    font-weight: 400;
    white-space: nowrap
}

@media(max-width:440px) {
    .Metadata--spa-full {
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .Metadata--spa-full .Metadata-label {
        white-space: normal
    }

    .Metadata--spa-full .Metadata-item {
        text-align: left;
        -webkit-align-items: left;
        -ms-flex-align: left;
        align-items: left;
        margin-left: 0;
        margin-right: 0;
        width: 45%;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }
}

@media(min-width:771px) {
    .Metadata--spa-full .Metadata-label {
        font-size: 14px;
        font-weight: 700
    }
}

@media(min-width:1031px) {
    .Metadata--spa-full .Metadata-item {
        display: block;
        width: 13%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 10px;
        padding-right: 10px
    }

    .Metadata--spa-full .Metadata-icon {
        width: 27px;
        height: 27px;
        margin-right: 6px;
        margin-bottom: 0
    }

    .Metadata--spa-full .Metadata-label {
        display: block;
        white-space: normal
    }

    .Metadata--spa-full .Metadata-item+.Metadata-item:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        background-color: #ddd;
        height: 60%;
        display: block
    }
}

.Metadata--spa-card {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto
}

.Metadata--spa-card .Metadata-item {
    text-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 25%;
    display: block;
    max-width: 80px;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    position: relative
}

.Metadata--spa-card .Metadata-label {
    display: block;
    white-space: normal;
    font-size: 11px;
    font-weight: 400
}

@media(max-width:769px) {
    .Metadata--spa-card .Metadata-label {
        font-size: 10px
    }
}

.Metadata--article-card,
.Metadata--article-full {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.Metadata--article-full .Metadata-item,
.Metadata--article-card .Metadata-item {
    text-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: .9em;
    margin-bottom: 5px
}

.Metadata--article-card .Metadata-item {
    font-size: 13px;
    margin-right: 1em
}

.Metadata--article-full .Metadata-item:last-child,
.Metadata--article-card .Metadata-item:last-child {
    margin-right: 0
}

.Metadata--article-full .Metadata-icon,
.Metadata--article-card .Metadata-icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    margin-bottom: 0
}

.Metadata--article-card .Metadata-icon {
    width: 17px;
    height: 17px
}

.Metadata--article-full .Metadata-label,
.Metadata--article-card .Metadata-label {
    font-size: 12px;
    display: inline;
    font-weight: 400;
    white-space: nowrap
}

.Metadata--article-full .Metadata-item {
    margin-right: 20px
}

@media(min-width:771px) {

    .Metadata--article-full .Metadata-label,
    .Metadata--article-card .Metadata-label {
        font-size: 14px;
        font-weight: 700
    }
}

.NotFound {
    max-width: 900px;
    background-color: rgba(255, 255, 255, .4);
    padding: 3vw 2vw;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px
}

.NotFound-inner {
    max-width: 600px;
    margin: 0 auto
}

.NotFound-title {
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 30px;
    color: #fff;
    margin-bottom: .5em
}

@media screen and (min-width:380px) {
    .NotFound-title {
        font-size: calc(30px + 30 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .NotFound-title {
        font-size: 60px
    }
}

.NotFound-message {
    font-family: playfair display, Georgia, serif;
    color: #fff;
    font-weight: 700;
    font-size: 19px;
    margin-bottom: .8em
}

@media screen and (min-width:380px) {
    .NotFound-message {
        font-size: calc(19px + 7 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .NotFound-message {
        font-size: 26px
    }
}

.NotFound-options {
    color: #fff;
    font-weight: 700
}

.NotFound-options a {
    color: #0c717a
}

@media(max-width:440px) {
    .NotFound {
        padding: 60px 2vw
    }
}

.SocialActions-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 400;
    padding-left: 12px;
    margin-bottom: 22px;
    font-weight: 700;
    font-family: playfair display, Georgia, serif
}

.SocialActions .Social {
    margin-left: 12px
}

.Social {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.Social-site+.Social-site {
    margin-left: 10px
}

.Social-link svg {
    fill: #fff;
    color: #fff
}

.Social-link--twitter .Icon {
    background-color: #1da1f2
}

.Social-link--facebook .Icon {
    background-color: #3b5998
}

.Social-link--facebook .Icon svg {
    -webkit-transform: scale(.8);
    -ms-transform: scale(.8);
    transform: scale(.8)
}

.Social-link--pinterest .Icon {
    background-color: #bd081c
}

.Social-link--instagram .Icon {
    background-color: #e4405f
}

.SpaBadge {
    position: relative;
    padding-left: 30px;
    padding-top: 13px;
    white-space: nowrap;
    text-align: left
}

.SpaBadge-title {
    font-family: playfair display, Georgia, serif;
    color: #fff;
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 8px
}

.SpaBadge-link {
    display: inline-block;
    padding: 8px 16px;
    border-radius: 2px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    background-color: #eb0a5e;
    text-decoration: none;
    font-weight: 700;
    transition: all .2s ease
}

.SpaBadge-link:hover {
    background-color: #a40f51
}

.SpaBadge-bubbles {
    margin-left: 2px
}

.SiteHeader {
    -webkit-flex: 0;
    -ms-flex: 0;
    flex: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #e4e3ea
}

.SiteHeader-inner {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 56px
}

.SiteHeader-logo img {
    width: 131px;
    height: 44px;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none
}

.SiteHeader-panel {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative
}

.SiteHeader-action {
    padding: 20px 0 15px;
    border-bottom: 3px solid transparent;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1.5px;
    font-weight: 700;
    white-space: nowrap;
    font-size: 11px
}

@media screen and (min-width:380px) {
    .SiteHeader-action {
        font-size: calc(11px + 3 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SiteHeader-action {
        font-size: 14px
    }
}

.SiteHeader-menuToggle {
    margin-left: auto;
    margin-right: 10px;
    font-size: 14px;
    position: relative;
    top: -1px
}

@media(min-width:771px) {
    .SiteHeader-menuToggle {
        display: none
    }
}

@media(max-width:770px) {
    .SiteHeader-panel {
        border-top: 1px solid #e4e3ea;
        display: none;
        position: absolute;
        top: 56px;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 1000;
        background-color: #fff;
        padding: 15px
    }

    .menu-open .SiteHeader-panel {
        display: block
    }

    .menu-open .SiteHeader-panel>* {
        width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }
}

@media(min-width:771px) {
    .SiteHeader-navItems {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        transition: opacity .3s ease
    }

    .search-open .SiteHeader-navItems {
        opacity: .2
    }

    .SiteHeader-navItem {
        margin-left: 10px
    }
}

@media screen and (min-width:771px) and (min-width:380px) {
    .SiteHeader-navItem {
        margin-left: calc(10px + 50 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:771px) and (min-width:1440px) {
    .SiteHeader-navItem {
        margin-left: 60px
    }
}

.SiteHeader-navLink {
    color: #212121;
    display: block;
    font-size: 11px
}

@media screen and (min-width:380px) {
    .SiteHeader-navLink {
        font-size: calc(11px + 3 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SiteHeader-navLink {
        font-size: 14px
    }
}

.SiteHeader-navLink:before {
    content: '+';
    margin-right: 5px
}

@media(min-width:771px) {
    .SiteHeader-navLink {
        color: #212121;
        display: block;
        font-size: 11px
    }
}

@media screen and (min-width:771px) and (min-width:380px) {
    .SiteHeader-navLink {
        font-size: calc(11px + 3 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:771px) and (min-width:1440px) {
    .SiteHeader-navLink {
        font-size: 14px
    }
}

@media(min-width:771px) {
    .SiteHeader-navLink:before {
        content: '+';
        margin-right: 5px
    }

    .SiteHeader-navLink:hover {
        border-bottom-color: #e4e3ea;
        transition: border-color .3s ease-in
    }

    .SiteHeader-navItem.is-current .SiteHeader-navLink {
        border-bottom-color: #6ae3dd
    }
}

.SiteHeader-search {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

@media(max-width:770px) {
    .SiteHeader-search {
        padding-top: 20px;
        border-top: 1px solid #e4e3ea;
        margin-top: 13px
    }
}

@media(min-width:771px) {
    .SiteHeader-search {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 10px 0 30px;
        background-color: rgba(255, 255, 255, .8)
    }
}

.SiteHeader-search-icon {
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    position: relative;
    top: 2px;
    margin-right: 10px;
    display: inline-block;
    padding-top: 12px;
    cursor: pointer
}



@media(max-width:769px) {}

@media(min-width:770px) {}

.SiteHeader-search-toggle {
    width: 100%
}

@media(min-width:770px) {
    .SiteHeader-search-toggle {
        width: 0;
        overflow: hidden;
        position: relative;
        top: 4px;
        box-sizing: content-box;
        transition: width .1s linear
    }
}

@media(min-width:440px) {
    .SiteHeader-search.is-open .SiteHeader-search-toggle {
        width: 200px;
        margin-right: 10px
    }
}

.SiteHeader-search-submit {
    color: #212121;
    display: none
}

.SiteHeader-search-submit:focus {
    outline: none
}

@media(min-width:1031px) {
    .SiteHeader-search-submit {
        display: block;
        padding-top: 15px;
        padding-bottom: 0
    }
}

.SiteFooter {
    width: 100%;
    background-color: #212121;
    color: #999;
    padding-top: 50px;
    margin-top: 50px;
    color: #adadad
}

.SiteFooter ::-moz-selection {
    background-color: #000;
    text-shadow: none;
    color: #fff
}

.SiteFooter ::selection {
    background-color: #000;
    text-shadow: none;
    color: #fff
}

@media(min-width:771px) {
    .SiteFooter-section {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }
}

@media(min-width:441px) {
    .SiteFooter-info {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }
}

@media(min-width:771px) {
    .SiteFooter-info {
        min-width: 240px;
        width: 25%;
        display: block;
        padding-right: 20px
    }
}

.SiteFooter-nav {
    width: 100%
}

@media(min-width:441px) {
    .SiteFooter-nav {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
}

@media(min-width:771px) {
    .SiteFooter-nav {
        width: 75%
    }
}

@media(min-width:1031px) {
    .SiteFooter-nav {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}

@media(min-width:441px) and (max-width:770px) {
    .SiteFooter-misc {
        width: 50%;
        margin-left: auto
    }
}

.SiteFooter-group {
    margin-bottom: 30px
}

@media(min-width:441px) {
    .SiteFooter-group {
        -webkit-flex: auto;
        -ms-flex: auto;
        flex: auto;
        width: 25%;
        padding-right: 10px
    }
}

@media(min-width:441px) and (max-width:1029px) {
    .SiteFooter-group {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        width: 50%
    }
}

.SiteFooter-group:last-child {
    padding-right: 0
}

.SiteFooter-group-title {
    font-family: playfair display, Georgia, serif;
    color: #fff;
    font-weight: 700;
    border-top: 2px solid #fff;
    padding-top: 12px;
    margin-bottom: 14px;
    font-size: 19px
}

@media screen and (min-width:380px) {
    .SiteFooter-group-title {
        font-size: calc(19px + 6 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SiteFooter-group-title {
        font-size: 25px
    }
}

.SiteFooter-logo {
    margin-bottom: 25px;
    display: block
}

@media(max-width:770px) {
    .SiteFooter-logo {
        margin-top: -12px
    }
}

.SiteFooter-social {
    margin-bottom: 30px
}

.SiteFooter-actions {
    margin-bottom: 30px
}

.SiteFooter-actions li {
    margin-bottom: 8px
}

.SiteFooter-actions a {
    text-decoration: none;
    font-weight: 700;
    color: #fff
}

.SiteFooter-actions .Icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    opacity: .7;
    position: relative;
    top: 2px
}

.SiteFooter-links li {
    list-style: none;
    line-height: 26px
}

.SiteFooter-links a {
    text-decoration: none;
    transition: color .2s ease-in-out
}

.SiteFooter-links a:hover {
    color: #fff
}

.SiteFooter-coda {
    width: 100%;
    border-top: 1px solid #616161;
    margin-top: 20px;
    padding: 16px 0 10px
}

.SiteFooter-links--inline {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.SiteFooter-links--inline li {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    margin-bottom: 8px;
    line-height: 1;
    white-space: nowrap;
    margin-right: 10px
}

@media screen and (min-width:380px) {
    .SiteFooter-links--inline li {
        margin-right: calc(10px + 15 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SiteFooter-links--inline li {
        margin-right: 25px
    }
}

.SiteFooter-smallprint {
    margin-left: auto;
    font-size: 13px;
    position: relative;
    top: 1px
}

.SearchResults-title,
.SearchResults-setTitle {
    margin-left: auto;
    margin-right: auto;
    font-family: playfair display, Georgia, serif;
    font-weight: 700;
    line-height: 1.2
}

.SearchResults-title {
    font-size: 40px;
    margin: .7em 0 .6em
}

@media screen and (min-width:380px) {
    .SearchResults-title {
        font-size: calc(40px + 36 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SearchResults-title {
        font-size: 76px
    }
}

.SearchResults-title mark {
    background-color: rgba(255, 249, 174, .59)
}

.SearchResults-title mark:before {
    content: '';
    display: block
}

.SearchResults-setTitle {
    font-size: 25px;
    margin-bottom: .8em
}

@media screen and (min-width:380px) {
    .SearchResults-setTitle {
        font-size: calc(25px + 20 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SearchResults-setTitle {
        font-size: 45px
    }
}

.SearchResults .l-tpl-header {
    padding-bottom: 25px
}

@media screen and (min-width:380px) {
    .SearchResults .l-tpl-header {
        padding-bottom: calc(25px + 25 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SearchResults .l-tpl-header {
        padding-bottom: 50px
    }
}

@media(min-width:771px) {

    .SearchResults-title,
    .l-tpl-header .SearchResults-setTitle {
        padding-left: 25px;
        padding-right: 25px
    }
}

@media(min-width:1031px) {

    .SearchResults-title,
    .l-tpl-header .SearchResults-setTitle {
        padding-left: 8.33333%;
        padding-right: 8.33333%
    }
}

.l-tpl-content .SearchResults-set {
    margin-top: 25px
}

@media screen and (min-width:380px) {
    .l-tpl-content .SearchResults-set {
        margin-top: calc(25px + 25 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .l-tpl-content .SearchResults-set {
        margin-top: 50px
    }
}

.SearchResults-more {
    margin-top: 25px;
    text-align: center
}

@media screen and (min-width:380px) {
    .SearchResults-more {
        margin-top: calc(25px + 25 * (100vw - 380px)/1060)
    }
}

@media screen and (min-width:1440px) {
    .SearchResults-more {
        margin-top: 50px
    }
}