html {
    scroll-behavior: smooth;
}

/* Navbar start */
.drop-navbar {
    animation-name: drop;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-duration: 500ms;
    animation-delay: 0.25s;
    transform: translateY(-100%);
}

@keyframes drop {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translate(0);
    }
}

/* navbar end */

.bg-hero {
    /* background-image: url('/images/bg-hero.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: white;
}

.bg-hero-1 {
    /* background-image: url('/images/excavator-import.jpg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: white;
}


.bg-hero-2 {
    /* background-image: url('/images/industrial-import.jpg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: white;
}

.bg-hero-3 {
    /* background-image: url('/images/car-import-short.jpeg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: white;
}

.bg-hero-4 {
    /* background-image: url('/images/all-seeds-2.jpeg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: white;
}

.effect,
.effect-1 {
    background-color: rgba(0, 0, 0, 0.5);
}

.slide-1,
.slide-2,
.slide-3,
.slide-4,
.slide-5 {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: white;
}

.slide-1 {
    /* background-image: url("/images/slider/576/1.jpg"); */
}

.slide-2 {
    /* background-image: url("/images/slider/576/2.jpg"); */
}

.slide-3 {
    /* background-image: url("/images/slider/576/3.jpg"); */
}

.slide-4 {
    /* background-image: url("/images/slider/576/4.jpg"); */
}

@media (min-width: 576px) {
    .slide-1 {
        /* background-image: url("/images/slider/1.jpg"); */
    }

    .slide-2 {
        /* background-image: url("/images/slider/2.jpg"); */
    }

    .slide-3 {
        /* background-image: url("/images/slider/3.jpg"); */
    }
}

.hero-button {
    border-radius: 100px;
    animation-name: flow;
    animation-delay: 1s;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    outline: 1px solid rgba(255, 255, 255, 1);
}

/* .parallax-1 {
    position: relative;
}

.parallax-1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/images/bg-hero-1.jpg");
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    filter: brightness(0.5);
} */

@keyframes flow {
    0% {
        outline: 1px solid rgba(255, 255, 255, 1);
    }

    50% {
        outline: 16px solid rgba(255, 255, 255, 0);
    }

    100% {
        outline: 1px solid rgba(255, 255, 255, 0);
    }
}

.horizontal-line {
    width: 5vw;
    height: 4px;
}

.vertical-line {
    width: 5vw;
    height: 4px;
}

.vertical-bar::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    background-color: white;
    transition: all;
    transition-timing-function: ease-out;
    transition-duration: 0.3s;
}

@media (min-width:1180px) {
    .bg-hero {
        /* background-image: url('/images/bg-hero.png'); */
    }

    /* .hero-button::before {
        content: "";
        position: absolute;
        left: -7px;
        top: -7px;
        right: -7px;
        bottom: -7px;
        border: 3px solid white;
        border-radius: 100px;
    } */
}

.icon-jump:hover {
    animation-name: jump;
    animation-duration: 100ms;
    animation-timing-function: ease-out;
    transform: translateY(0) scale(1);
    animation-fill-mode: both;
}

@keyframes jump {
    100% {
        transform: translateY(0px) scale(0.86)
    }
}

.why-icon-card {
    position: relative;
}

.why-icon-card:nth-child(1)::before {
    content: "";
    position: absolute;
    left: -10px;
    top: 10px;
    bottom: 10px;
    right: -10px;
    background-color: rgba(254, 139, 105, 0.5);
    z-index: -1;
    border-radius: 20px;
}

.why-icon-card:nth-child(2)::before {
    content: "";
    position: absolute;
    left: -10px;
    top: 10px;
    bottom: 10px;
    right: -10px;
    background-color: rgba(24, 119, 242, 0.5);
    z-index: -1;
    border-radius: 20px;
}

.why-icon-card:nth-child(3)::before {
    content: "";
    position: absolute;
    left: -10px;
    top: 10px;
    bottom: 10px;
    right: -10px;
    background-color: rgba(39, 174, 96, 0.5);
    z-index: -1;
    border-radius: 20px;
}

.ellipse-contact {
    width: 160px;
    height: 160px;
    background-color: #FA949D;
    border-radius: 100%;
    position: absolute;
    right: -12%;
    bottom: -9%;
}

@media (min-width: 1180px) {
    .ellipse-contact {
        width: 220px;
        height: 220px;
        background-color: #FA949D;
        border-radius: 100%;
        position: absolute;
        right: -12%;
        bottom: -9%;
    }
}

.stripe-skew {
    position: relative;
}

.stripe-skew::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 25%;
    left: 20%;
    transform: skewX(30deg);
    background-color: white;
    z-index: -1;
}

.stripe-skew::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 25%;
    left: 60%;
    transform: skewX(30deg);
    background-color: white;
    z-index: -1;
}

/* Contact */
.bg-office {
    /* background-image: url('../images/tabs/sportmassage1.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 576px;
}

/* /contact */

.why-choose-us {
    /* background-image: url('/images/why.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.client-say {
    /* background-image: url('/images/other/happy-client.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* slide-up */
.slide-up {
    animation-name: slide-up-loader;
    animation-duration: 750ms;
    animation-delay: 2s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes slide-up-loader {
    50% {}

    100% {
        opacity: 0;
        transform: scaleY(1);
        transform: translateY(-100%);
    }
}